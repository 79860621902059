import { Component, Input, OnInit } from '@angular/core';
import { DynamicFieldObject } from '../dynamic-field.object';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent implements OnInit {
  @Input() fieldComponent?: DynamicFieldObject;
  @Input() name = '';
  @Input() description = '';
  @Input() editorType: string | undefined;

  ngOnInit(): void {
    if (this.fieldComponent) {
      this.name = this.name || this.fieldComponent.data.name;
      this.description = this.fieldComponent.data.description || '';
      this.editorType = this.editorType || this.fieldComponent.component.editorType;
    }
  }
}
