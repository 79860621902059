<div class="img-editor">
  <img *ngIf="currentMimeType?.includes('image')" [src]="currentURL" alt="image" />

  <div
    *ngIf="currentURL && !currentMimeType?.includes('image')"
    class="text-muted"
  >
    No preview available.
  </div>

  <app-file-upload
    (newFileSelected)="changeImage($event)"
    [currentURL]="currentURL"
    [data]="data"
    [fileType]="'image'"
  />
</div>

