export function deepCopy<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item)) as T;
  }

  const copiedObject: Partial<T> = {} as Partial<T>;
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      copiedObject[key] = deepCopy(obj[key]);
    }
  }

  return copiedObject as T;
}

export function isStructOrRef(target: string, type: string): boolean {
  return target === `StructRef<${type}>` || target === `Struct<${type}>`;
}

export const instant = async <T>(method: (...args: unknown[]) => T | Promise<T>) => {
  return new Promise<T>((resolve) => {
    setTimeout(async () => resolve(await method()), 0);
  });
};

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function generateRandomString(
  length: number,
  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
): string {
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function debounce<T extends (...args: unknown[]) => unknown>(func: T, timeout = 300): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<T>) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export function prettifyName(input: string | undefined): string {
  return addSpacesBeforeUpperChars(input);
}

export function addSpacesBeforeUpperChars(input: string | undefined): string {
  if (!input) return '';
  return input
    .split('')
    .map((char, index) => {
      if (char === char.toUpperCase() && char !== char.toLowerCase() && index !== 0) {
        return ' ' + char;
      }
      return char;
    })
    .join('');
}
