import { Pipe, PipeTransform } from '@angular/core';
import { prettifyName } from '../utils';

@Pipe({
  name: 'prettifyName',
  standalone: true,
})
export class PrettifyNamePipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (typeof value !== 'string') return value;
    return prettifyName(value);
  }
}
