<div class="card">
  <div class="card-header justify-content-between d-flex">
    <div class="align-content-center">
      Set Variable Action
    </div>
    <button (click)="onDelete()" class="btn btn-danger">
      <i class="bi bi-trash"></i>
    </button>
  </div>

  <div>
    Variable:

    <div class="d-flex">
      <select
        (change)="onVariableChange()"
        [(ngModel)]="variable"
        class="form-select"
      >
        <option *ngFor="let variable of variables" [ngValue]="variable.variableRef">
          {{ variable.name }}
        </option>
      </select>

      <button
        (click)="onViewStruct(currentVariable)"
        *ngIf="currentVariable"
        class="btn btn-primary"
      >
        <i class="bi-box-arrow-up-right"></i>
      </button>
    </div>
  </div>

  <div *ngIf="currentVariable">
    New Value:

    <input
      (change)="updateString()"
      *ngIf="currentVariable.valueType === VariableType.String"
      [(ngModel)]="newValue"
      class="form-control"
      type="text"
    />

    <input
      (change)="updateNumber()"
      *ngIf="currentVariable.valueType === VariableType.Number"
      [(ngModel)]="newValue"
      class="form-control"
      type="number"
    />

    <div *ngIf="currentVariable.valueType === VariableType.Boolean" class="form-check form-switch w-100">
      <input
        (change)="updateBoolean()"
        [(ngModel)]="newValue"
        class="form-check-input"
        role="switch" type="checkbox"
      />
    </div>

    <div *ngIf="currentVariable.valueType === VariableType.Color" class="form-check form-switch w-100">
      <input
        (colorPickerSelect)="updateColor($event)"
        [colorPicker]="String(newValue)"
        [cpAlphaChannel]="'always'"
        [cpOKButtonText]="'Save'"
        [cpOKButton]="true"
        [cpOutputFormat]="'hex'"
        [style.background]="newValue"
        [value]="newValue"
        style="width: 100px"
      />
    </div>
  </div>
</div>
