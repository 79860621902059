import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataInstance } from '../../../../models/data/DataInstance';
import { DataService } from '../../../../_services/data-management/data.service';
import { filter, firstValueFrom } from 'rxjs';
import { LoadingScreenService } from '../../../../_services/UI-elements/loading-screen.service';
import { DynamicFieldService } from '../../../../_services/data-management/dynamic-field.service';

@Component({
  selector: 'app-map-editor',
  templateUrl: './map-editor.component.html',
  styleUrls: ['./map-editor.component.scss'],
})
export class MapEditorComponent implements OnInit {
  mapInstance?: DataInstance;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private fieldFactory: DynamicFieldService,
    private loadingScreenService: LoadingScreenService,
  ) {}

  async ngOnInit() {
    await this.loadingScreenService.show(async () => {
      await firstValueFrom(this.fieldFactory.dynamicFieldServiceInitialized.pipe(filter(Boolean)));
      this.route.params.subscribe(async (params) => {
        const mapUid = params['mapUid'];
        if (!mapUid) return;

        const mapInstance = await this.dataService.getDataInstance(mapUid);
        if (!mapInstance) throw new Error('Map: ' + mapUid + ' not found');

        this.mapInstance = mapInstance;
      });
    });
  }
}
