import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../../../../_services/data-management/data.service';
import { FieldData } from '../../../../models/data/FieldData';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { DynamicFieldComponent } from '../../dynamic-field.component';

@Component({
  selector: 'app-check-field',
  templateUrl: './check-field.component.html',
  styleUrls: ['./check-field.component.scss'],
})
export class CheckFieldComponent implements OnInit, OnDestroy, DynamicFieldComponent<FieldData<boolean> | undefined> {
  @Input({
    transform: (value: FieldData<boolean | string>): FieldData<boolean> | undefined => {
      //eslint-disable-next-line
      value.value = `${value.value}` == 'true'; // raarste eslint issue ooit
      return value as FieldData<boolean>;
    },
  })
  data: FieldData<boolean> | undefined;

  @Output() checkToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  saveButtonSubscription: Subscription | undefined;

  constructor(
    private dataService: DataService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    if (!this.data) this.checkToggled.emit(true);
    else this.checkToggled.emit(this.data.value);
  }

  update() {
    if (!this.data) throw new Error('Data not found');
    this.checkToggled.emit(this.data.value);
    this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value).then();
    this.showAlert();
  }

  showAlert() {
    if (!this.data) return;
    this.alertService.showAlert('Updating ' + this.data.name + '...', BootstrapClass.INFO);
  }

  ngOnDestroy() {
    if (this.saveButtonSubscription) this.saveButtonSubscription.unsubscribe();
  }
}
