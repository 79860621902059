<input
  (change)="this.update()"
  *ngIf="data"
  [(ngModel)]="data.value"
  class="form-control form-control-sm"
  max="{{ max }}"
  min="{{ min }}"
  placeholder="{{ data.description }}"
  type="number"
/>
