import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../../../_services/data-management/data.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { Variable } from '../../../../models/schema/Variable';
import { ConfirmationModalService } from '../../../../_services/UI-elements/confirmation-modal.service';
import { VariableType } from '../../../../models/schema/VariableType';
import { LoadingScreenService } from '../../../../_services/UI-elements/loading-screen.service';

@Component({
  selector: 'app-variable-editor',
  templateUrl: './variable-editor.component.html',
  styleUrls: ['./variable-editor.component.scss'],
})
export class VariableEditorComponent implements OnInit, OnDestroy {
  variableRef?: string;
  variable?: Variable;
  newName = '';

  newDescription?: string;
  newValueType?: VariableType;
  newStartValue: string | boolean | number = '';

  protected readonly valueTypeOptions = Object.values(VariableType);
  protected readonly String = String;
  protected readonly Number = Number;
  protected readonly VariableType = VariableType;
  private routeSub?: Subscription;

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private confirmService: ConfirmationModalService,
    private loadingScreenService: LoadingScreenService,
  ) {}

  ngOnInit(): void {
    this.routeSub = this.activatedRoute.params.subscribe(async (params) => {
      this.variableRef = params['variableRef'];

      if (!this.variableRef) {
        this.router.navigate(['/home/Variable']).then();
        this.alertService.showAlert('Variable not found in the url', BootstrapClass.DANGER);
        return;
      }

      this.variable = await this.dataService.getVariable(this.variableRef);

      if (!this.variable) {
        this.router.navigate(['/home/Variable']).then();
        this.alertService.showAlert('Variable instance not found', BootstrapClass.DANGER);
        return;
      }

      this.newName = this.variable.name;
      this.newDescription = this.variable.description;
      this.newValueType = this.variable.valueType;

      if (this.variable.startValue && this.variable.valueType) {
        switch (this.variable.valueType) {
          case VariableType.Number:
            this.newStartValue = parseFloat(this.variable.startValue);
            break;
          case VariableType.Boolean:
            this.newStartValue = this.variable.startValue === 'true';
            break;
          case VariableType.String:
          case VariableType.Color:
            this.newStartValue = this.variable.startValue;
            break;
        }
      }
    });
  }

  onValueTypeChange() {
    if (!this.variable) {
      return;
    }

    switch (this.newValueType) {
      case VariableType.Boolean:
        this.newStartValue = false;
        break;
      case VariableType.Number:
        this.newStartValue = 0;
        break;
      case VariableType.Color:
        this.newStartValue = '#ffffff';
        break;
      case VariableType.String:
      default:
        this.newStartValue = '';
        break;
    }
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  async saveVariable(): Promise<void> {
    if (!this.variable) {
      return;
    }

    if (this.newValueType === undefined) {
      throw new Error('newValueType must be set');
    }

    if (this.variable.valueType !== this.newValueType) {
      if (
        !confirm(
          'Since the valuetype has changed, all instances of this variable will be removed in VariableComparisons and SetVariableActions. Are you sure you want to continue?',
        )
      )
        return;
    }

    this.variable.name = this.newName;
    this.variable.description = this.newDescription;
    this.variable.valueType = this.newValueType;
    this.variable.startValue = this.newStartValue.toString();

    await this.dataService.updateVariable(this.variable);
    this.alertService.showAlert('Variable saved', BootstrapClass.SUCCESS);
  }

  async deleteVariable() {
    if (!this.variable) return;

    const confirmed = await firstValueFrom(
      this.confirmService.confirm('Are you sure you want to delete variable ' + this.variable.name + '?'),
    );
    if (!confirmed) return;

    return this.loadingScreenService.show(async () => {
      if (!this.variable) return;
      await this.dataService.deleteVariable(this.variable, { throwError: true });
      return this.router.navigate(['/home/Variable']);
    });
  }

  updateColor(color: string) {
    this.newStartValue = color;
  }

  onNewNumber() {
    this.newStartValue = Math.round(Number(this.newStartValue));
  }
}
