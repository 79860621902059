<div>
  <app-visual-editor
    (addPreset)="addPreset($event)"
    (nameChanged)="onUpdateName($event)"
    (selected)="selected = $event"
    *ngIf="background && backgroundInstance"
    [backgroundInstance]="backgroundInstance"
    [background]="background"
    [canvasRatio]="canvasRatio"
    [contextMenuOptions]="contextMenu"
    [targets]="visualTargets"
  />
</div>
<div *ngIf="dataInstance && dataInstance.dataType === 'DragAndDropActivity'" class="card-body">
  <div class="row mb-2">
    <div class="col-4">
      <h4>Draggable Objects</h4>
      <button
        (click)="addInstance('Draggable', 'Rectangle')"
        class="btn btn-outline-primary btn-space"
        type="button"
      >
        Add Draggable <i class="bi bi-arrows-move"></i>
      </button>
      <div class="accordion" id="accordionDraggables">
        <div *ngFor="let instance of draggableInstances" class="accordion-item">
          <div class="accordion-header">
            <div [attr.aria-controls]="'collapse'+instance.uid"
                 [attr.data-bs-target]="'#collapse' + instance.uid"
                 [ngClass]="{'node-selected': selected === instance.uid}"
                 aria-expanded="false"
                 class="accordion-button collapsed d-flex justify-content-between"
                 data-bs-toggle="collapse" type="button">
              {{ visualTargetNames[instance.uid] }}
              <button (click)="deleteInstance(instance, 'draggables')" class="btn btn-danger btn-space">
                <span class="bi bi-trash"></span>
              </button>
            </div>
          </div>
          <div class="accordion-collapse collapse" data-bs-parent="#accordionDraggables" id="collapse{{instance.uid}}">
            <div class="accordion-body">
              <app-struct-instance-editor
                [currentResourceUid]="instance.uid"
                [resourceStructType]="instance.dataType"
                editorType="SeamlessInline"
                titleOverride="{{ visualTargetNames[instance.uid] }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <h4>Drop Targets</h4>
      <button (click)="addInstance('DropPoint', 'Rectangle')" class="btn btn-outline-primary btn-space"
              type="button">
        Add Drop Point <i class="bi bi-pin"></i>
      </button>
      <button (click)="addInstance('DropArea','Rectangle')" class="btn btn-outline-primary btn-space"
              type="button">
        Add Drop Area <i class="bi bi-square"></i>
      </button>
      <div class="accordion" id="accordionTargets">
        <div *ngFor="let instance of targetInstances" class="accordion-item">
          <div class="accordion-header">
            <div [attr.aria-controls]="'collapse' + instance.uid"
                 [attr.data-bs-target]="'#collapse' + instance.uid"
                 [ngClass]="{'node-selected': selected === instance.uid}"
                 aria-expanded="false"
                 class="accordion-button collapsed d-flex justify-content-between"
                 data-bs-toggle="collapse" type="button">
              {{ visualTargetNames[instance.uid] }}
              <button (click)="deleteInstance(instance, 'targets')" class="btn btn-danger btn-space">
                <span class="bi bi-trash"></span>
              </button>
            </div>
          </div>
          <div class="accordion-collapse collapse" data-bs-parent="#accordionTargets" id="collapse{{instance.uid}}">
            <div class="accordion-body">
              <app-struct-instance-editor
                [currentResourceUid]="instance.uid"
                [resourceStructType]="instance.dataType"
                editorType="SeamlessInline"
                titleOverride="{{ visualTargetNames[instance.uid] }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <h4>Visual Elements</h4>
      <button (click)="addVisualElement('Rectangle')" class="btn btn-outline-primary btn-space" type="button">
        Add Rectangle Visual <i class="bi bi-image"></i>
      </button>
      <button (click)="addVisualElement('Circle')" class="btn btn-outline-primary btn-space" type="button">
        Add Circle Visual <i class="bi bi-image"></i>
      </button>
      <div class="accordion" id="accordionVisualElements">
        <div *ngFor="let instance of visualElementInstances" class="accordion-item">
          <div class="accordion-header">
            <!-- The spaces in the name nee te be replaced for the accordion to work -->
            <div [attr.aria-controls]="'collapse' + instance.uid"
                 [attr.data-bs-target]="'#collapse' + instance.uid"
                 [ngClass]="{'node-selected': selected === instance.uid}"
                 aria-expanded="false"
                 class="accordion-button collapsed d-flex justify-content-between"
                 data-bs-toggle="collapse" type="button">
              {{ visualTargetNames[instance.uid] }}
              <button (click)="deleteInstance(instance, 'visualElements')" class="btn btn-danger btn-space"
                      type="button">
                <span class="bi bi-trash"></span>
              </button>
            </div>
          </div>
          <div class="accordion-collapse collapse" data-bs-parent="#accordionVisualElements"
               id="collapse{{instance.uid}}">
            <div class="accordion-body">
              <app-struct-instance-editor
                [currentResourceUid]="instance.uid"
                [resourceStructType]="instance.dataType"
                editorType="SeamlessInline"
                titleOverride="{{ visualTargetNames[instance.uid] }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="dataInstance && dataInstance.dataType === 'ClickActivity'" class="card-body">
  <div class="row mb-2">
    <div class="col-6">
      <h4>Click Targets</h4>
      <button
        (click)="addInstance('ClickTarget', 'Rectangle')"
        class="btn btn-outline-primary btn-space"
        type="button"
      >
        Add Rectangle Target <i class="bi bi-crosshair"></i>
      </button>
      <button
        (click)="addInstance('ClickTarget', 'Circle')"
        class="btn btn-outline-primary btn-space"
        type="button"
      >
        Add Circle Target <i class="bi bi-crosshair"></i>
      </button>
      <div class="accordion" id="accordionClickTargets">
        <div *ngFor="let instance of targetInstances" class="accordion-item">
          <div class="accordion-header">
            <div [attr.aria-controls]="'collapse' + instance.uid"
                 [attr.data-bs-target]="'#collapse' + instance.uid"
                 [ngClass]="{'node-selected': selected === instance.uid}"
                 aria-expanded="false"
                 class="accordion-button collapsed d-flex justify-content-between"
                 data-bs-toggle="collapse" type="button">
              {{ visualTargetNames[instance.uid] }}
              <button (click)="deleteInstance(instance, 'targets')" class="btn btn-danger btn-space" type="button">
                <span class="bi bi-trash"></span>
              </button>
            </div>
          </div>
          <div class="accordion-collapse collapse" data-bs-parent="#accordionClickTargets"
               id="collapse{{instance.uid}}">
            <div class="accordion-body">
              <app-struct-instance-editor
                [currentResourceUid]="instance.uid"
                [resourceStructType]="instance.dataType"
                editorType="SeamlessInline"
                titleOverride="{{ visualTargetNames[instance.uid] }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <h4>Visual Elements</h4>
      <button (click)="addVisualElement('Rectangle')" class="btn btn-outline-primary btn-space" type="button">
        Add Rectangle Visual <i class="bi bi-image"></i>
      </button>
      <button (click)="addVisualElement('Circle')" class="btn btn-outline-primary btn-space" type="button">
        Add Circle Visual <i class="bi bi-image"></i>
      </button>
      <div class="accordion" id="accordionVisualElementsClick">
        <div *ngFor="let instance of visualElementInstances" class="accordion-item">
          <div class="accordion-header">
            <!-- The spaces in the name nee te be replaced for the accordion to work -->
            <div [attr.aria-controls]="'collapse' + instance.uid"
                 [attr.data-bs-target]="'#collapse' + instance.uid"
                 [ngClass]="{'node-selected': selected === instance.uid}"
                 aria-expanded="false"
                 class="accordion-button collapsed d-flex justify-content-between"
                 data-bs-toggle="collapse" type="button">
              {{ visualTargetNames[instance.uid] }}
              <button (click)="deleteInstance(instance, 'visualElements')" class="btn btn-danger btn-space"
                      type="button">
                <span class="bi bi-trash"></span>
              </button>
            </div>
          </div>
          <div class="accordion-collapse collapse" data-bs-parent="#accordionVisualElementsClick"
               id="collapse{{instance.uid}}">
            <div class="accordion-body">
              <app-struct-instance-editor
                [currentResourceUid]="instance.uid"
                [resourceStructType]="instance.dataType"
                editorType="SeamlessInline"
                titleOverride="{{ visualTargetNames[instance.uid] }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="dataInstance && dataInstance.dataType === 'Map'" class="card-body">
  <div class="row mb-2">
    <div class="col-6">
      <h4>Map Pins</h4>
      <button
        (click)="addMapPinLocation()"
        class="btn btn-outline-primary btn-space"
        type="button"
      >
        Add Map Pin <i class="bi bi-pin"></i>
      </button>
      <div class="accordion" id="accordionMapPins">
        <div *ngFor="let instance of mapPinInstances" class="accordion-item">
          <div class="accordion-header">
            <div [attr.aria-controls]="'collapse' + instance.uid"
                 [attr.data-bs-target]="'#collapse' + instance.uid"
                 [ngClass]="{'node-selected': selected === instance.uid}"
                 aria-expanded="false"
                 class="accordion-button collapsed d-flex justify-content-between"
                 data-bs-toggle="collapse" type="button">
              {{ visualTargetNames[instance.uid] }}
              <button (click)="deleteInstance(instance, 'mapLocations')" class="btn btn-danger btn-space" type="button">
                <span class="bi bi-trash"></span>
              </button>
            </div>
          </div>
          <div class="accordion-collapse collapse" data-bs-parent="#accordionMapPins"
               id="collapse{{instance.uid}}">
            <div class="accordion-body">
              <app-struct-instance-editor
                [currentResourceUid]="instance.uid"
                [resourceStructType]="instance.dataType"
                editorType="SeamlessInline"
                titleOverride="{{ visualTargetNames[instance.uid] }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <h4>Visual Elements</h4>
      <button (click)="addVisualElement('Rectangle')" class="btn btn-outline-primary btn-space" type="button">
        Add Rectangle Visual <i class="bi bi-image"></i>
      </button>
      <button (click)="addVisualElement('Circle')" class="btn btn-outline-primary btn-space" type="button">
        Add Circle Visual <i class="bi bi-image"></i>
      </button>
      <div class="accordion" id="accordionVisualElementsMap">
        <div *ngFor="let instance of visualElementInstances" class="accordion-item">
          <div class="accordion-header">
            <!-- The spaces in the name nee te be replaced for the accordion to work -->
            <div [attr.aria-controls]="'collapse' + instance.uid"
                 [attr.data-bs-target]="'#collapse' + instance.uid"
                 [ngClass]="{'node-selected': selected === instance.uid}"
                 aria-expanded="false"
                 class="accordion-button collapsed d-flex justify-content-between"
                 data-bs-toggle="collapse" type="button">
              {{ visualTargetNames[instance.uid] }}
              <button (click)="deleteInstance(instance, 'visualElements')" class="btn btn-danger btn-space"
                      type="button">
                <span class="bi bi-trash"></span>
              </button>
            </div>
          </div>
          <div class="accordion-collapse collapse" data-bs-parent="#accordionVisualElementsMap"
               id="collapse{{instance.uid}}">
            <div class="accordion-body">
              <app-struct-instance-editor
                [currentResourceUid]="instance.uid"
                [resourceStructType]="instance.dataType"
                editorType="SeamlessInline"
                titleOverride="{{ visualTargetNames[instance.uid] }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
