import { Component, EventEmitter, Input, Output } from '@angular/core';
import fieldData, { FieldData } from '../../../../models/data/FieldData';
import { DataService } from '../../../../_services/data-management/data.service';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { DynamicFieldComponent } from '../../dynamic-field.component';

@Component({
  selector: 'app-number-input-field',
  templateUrl: './number-input-field.component.html',
  styleUrls: ['./number-input-field.component.scss'],
})
export class NumberInputFieldComponent implements DynamicFieldComponent<FieldData<string> | undefined> {
  @Input({
    transform: (value: FieldData<string | string[] | unknown>) => fieldData.transform(value),
  })
  data: FieldData<string> | undefined;

  @Input() min = 0;
  @Input() max = 10;
  @Output() numberChanged = new EventEmitter<string>();

  constructor(
    private dataService: DataService,
    private alertService: AlertService,
  ) {}

  update() {
    if (!this.data) throw new Error('Data not found');
    this.numberChanged.emit(this.data.value);
    this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value).then();
    this.showAlert();
  }

  showAlert() {
    if (!this.data) return;
    this.alertService.showAlert('Updating ' + this.data.name + '...', BootstrapClass.INFO);
  }
}
