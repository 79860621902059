import { Component, Input, OnInit } from '@angular/core';
import { DynamicFieldService } from '../../../../_services/data-management/dynamic-field.service';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { DataService } from '../../../../_services/data-management/data.service';
import { FlowchartNode } from '../../../../models/data/FlowchartNode';
import { ConfirmationModalService } from '../../../../_services/UI-elements/confirmation-modal.service';
import { LoadingScreenService } from '../../../../_services/UI-elements/loading-screen.service';
import { DataInstance } from '../../../../models/data/DataInstance';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
  @Input() uid = '';

  currentActivity?: DataInstance;
  currentActivitySubscription: Subscription | undefined;

  constructor(
    private fieldFactory: DynamicFieldService,
    private dataService: DataService,
    private confirmService: ConfirmationModalService,
    private loadingScreenService: LoadingScreenService,
  ) {}

  async ngOnInit() {
    this.currentActivitySubscription = this.dataService.currentActivityChanged$.subscribe(async (activity) => {
      await this.loadingScreenService.show(async () => {
        await firstValueFrom(this.fieldFactory.dynamicFieldServiceInitialized.pipe(filter(Boolean)));
        await this.update(activity);
      });
    });
  }

  private async update(newActivity: FlowchartNode | undefined) {
    if (!newActivity) {
      this.currentActivity = undefined;
      return;
    }

    this.currentActivity = await this.dataService.getDataInstance(newActivity.dataInstanceUid);
    if (!this.currentActivity) {
      console.warn(`Instance ${newActivity.dataInstanceUid} not found`);
      return;
    }
  }
}
