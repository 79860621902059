import { Component, Input } from '@angular/core';
import fieldData, { FieldData } from '../../../../models/data/FieldData';
import { DynamicFieldComponent } from '../../dynamic-field.component';

@Component({
  selector: 'app-audio-field',
  templateUrl: './audio-field.component.html',
  styleUrls: ['./audio-field.component.scss'],
})
export class AudioFieldComponent implements DynamicFieldComponent<FieldData<string> | undefined> {
  @Input({
    transform: (value: FieldData<string | string[] | unknown>) => fieldData.transform(value),
  })
  data: FieldData<string> | undefined;

  name?: string;
  url?: string;

  async changeAudio(event: { name: string; url: string }) {
    this.name = event.name;
    this.url = event.url;
  }
}
