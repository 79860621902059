import { Field, isField } from './Field';
import { Migration } from '../data/Migration';

export interface StructType {
  typeId: string;
  name: string;
  description: string;
  fields: Field[];
  type: 'StructType';
  fieldMigrations: Migration[] | undefined;
  isResource: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isStructType(obj: any): obj is StructType {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.typeId === 'string' &&
    typeof obj.name === 'string' &&
    typeof obj.description === 'string' &&
    typeof obj.isResource === 'boolean' &&
    Array.isArray(obj.fields) &&
    obj.fields.every((field: unknown) => isField(field)) && // Assuming you have a type guard for Field
    obj.type === 'StructType'
  );
}
