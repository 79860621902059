<!-- The flowchart screen -->
<div>
  <app-flowchart [flowchartType]="'module'"></app-flowchart>
</div>

<!--Buttons to save or add kennisnodes-->
<div class="d-flex justify-content-center align-items-center gap-2 mt-2">
  <p class="h4 mb-0 me-2">{{ moduleName }}</p>

  <button (click)="openOverview()" class="btn btn-primary">
    <i class="bi bi-info-circle"></i> Overview
  </button>

  <button class="btn btn-primary" data-bs-target="#newKennisNodeModal" data-bs-toggle="modal">
    <i class="bi bi-plus-circle"></i> Add node
  </button>

  <a
    *ngIf="environment.playGameUrl && moduleId"
    class="btn btn-primary"
    href="{{ environment.playGameUrl }}?offline=1&module={{ moduleId }}"
    target="_blank"
  >
    <i class="bi bi-play-circle"></i>
    Play
  </a>
</div>

<!--The editor where the current activity or the overview of the mission can be added-->
<div class="container mt-2 pb-2">
  <app-flowchart-node
    (deleteNode)="deleteKennisNode($event)"
    *ngIf="currentKennisNode"
    [node]="currentKennisNode"></app-flowchart-node>

  <app-struct-instance-editor
    *ngIf="currentKennisNode === undefined && moduleFieldData !== undefined"
    [data]="moduleFieldData"></app-struct-instance-editor>
</div>

<!-- Add node Modal -->
<div
  #newKennisNodeModal
  aria-hidden="true"
  aria-labelledby="newKennisNodeLabel"
  class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  id="newKennisNodeModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newKennisNodeModalLabel">Add new Kennisnode</h5>
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="modal-body">
        <input #kennisNodeTitle class="form-control" placeholder="Title of kennisnode" type="text"/>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Close</button>
        <button (click)="onAddKennisNode(kennisNodeTitle.value)" class="btn btn-primary" data-bs-dismiss="modal"
                type="button">Add
        </button>
      </div>
    </div>
  </div>
</div>
