<div class="d-flex justify-content-between gap-2">
  <span class="align-content-center">
     X:
  </span>
  <input
    (change)="this.update()"
    *ngIf="data"
    [(ngModel)]="data.value.x"
    class="form-control form-control-sm"
    placeholder="X"
    type="number"
  />
  <span class="align-content-center">
    Y:
  </span>
  <input
    (change)="this.update()"
    *ngIf="data"
    [(ngModel)]="data.value.y"
    class="form-control form-control-sm"
    placeholder="Y"
    type="number"
  />
</div>
