import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../_services/data-management/data.service';
import { ConfirmationModalService } from '../../../_services/UI-elements/confirmation-modal.service';
import { environment } from '../../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingScreenService } from '../../../_services/UI-elements/loading-screen.service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { AlertService } from '../../../_services/UI-elements/alert-service';

type Module = {
  moduleUid: string;
  moduleId: string;
  name: string;
  icon: string;
};

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.scss'],
})
export class ModuleListComponent {
  modules: Module[] = [];
  structTypeDescription = '';

  protected readonly environment = environment;

  constructor(
    private dataService: DataService,
    private router: Router,
    private confirmService: ConfirmationModalService,
    private loadingScreenService: LoadingScreenService,
    private alertService: AlertService,
  ) {
    this.getModules().then();
  }

  getModules() {
    return this.loadingScreenService.show(async () => {
      const modules = await this.dataService.getDataInstancesPerStructType('Module');

      // For each module, get the module data instance and add the name to the modules array
      this.modules = [];

      for (const module of modules) {
        this.modules.push({
          moduleUid: module.uid,
          moduleId: module.fieldValues.find((fv) => fv.field === 'moduleId')?.value as string,
          name: module.fieldValues.find((fv) => fv.field === 'displayName')?.value as string,
          icon: module.fieldValues.find((fv) => fv.field === 'icon')?.value as string,
        });
      }

      this.modules.sort((a, b) => {
        if (!a.name) return 1;
        if (!b.name) return -1;
        return a.name.localeCompare(b.name);
      });

      this.structTypeDescription = this.dataService.getStructType('Module')?.description;
    });
  }

  openModule(moduleUid: string) {
    this.router.navigate(['/home/module', moduleUid]).then();
  }

  createModule() {
    return this.loadingScreenService.show(async () => {
      const newModule = await this.dataService.initStruct('Module');

      // Initialize the KennisBoom data instance, and reference it in the module data instance
      for (const fieldValue of newModule.fieldValues) {
        if (fieldValue.field === 'kennisboom') {
          const newKennisBoom = await this.dataService.initStruct('Kennisboom');
          fieldValue.value = newKennisBoom.uid;
          await this.dataService.updateDataInstance(newModule);
        }
      }

      this.openModule(newModule.uid);
    });
  }

  async deleteModule(moduleUid: string, force = false): Promise<unknown> {
    if (!force) {
      // Open a confirmation modal
      const confirmed = await firstValueFrom(this.confirmService.confirm('Are you sure you want to delete this module?'));
      if (!confirmed) return;
    }

    return this.loadingScreenService.show(async () => {
      try {
        const module = await this.dataService.getDataInstanceFromDB(
          this.dataService.currentGameId,
          this.dataService.currentDataPackage,
          'module',
          moduleUid,
        );

        await this.dataService.deleteDataInstance(module, {
          throwError: true,
          force,
        });

        return await this.getModules();
      } catch (e) {
        if (
          !force &&
          e instanceof HttpErrorResponse &&
          e.status === 409 &&
          confirm(
            'This module is referenced in other places. Are you sure you want to delete it? This is a destructive action and cannot be undone.',
          )
        ) {
          return this.deleteModule(moduleUid, true);
        }

        throw e;
      }
    });
  }

  copyResourceIdToClipboard(resourceUid: string) {
    navigator.clipboard
      .writeText(resourceUid)
      .then(() => {
        this.alertService.showAlert('Resource ID copied to clipboard: ' + resourceUid, BootstrapClass.SUCCESS);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.alertService.showAlert('Failed to copy Resource ID!', BootstrapClass.DANGER);
      });
  }
}
