import { Component, OnInit, TemplateRef } from '@angular/core';
import { HTTPRequestService } from '../../../_services/data-management/HTTP-request.service';
import { environment } from '../../../../environments/environment';
import { EnumType, isEnumType } from '../../../models/schema/EnumType';
import { isStructType, StructType } from '../../../models/schema/StructType';
import { isSelectType, SelectType } from '../../../models/schema/SelectType';
import { Router } from '@angular/router';
import { DataService } from '../../../_services/data-management/data.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { isSelectTypeOption, SelectTypeOption } from '../../../models/schema/SelectTypeOption';
import { Field, isField } from '../../../models/schema/Field';
import { HttpErrorResponse } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

type FilterType = 'StructType' | 'SelectType' | 'EnumType' | 'Resource' | 'All';

@Component({
  selector: 'app-schema-list',
  templateUrl: './schema-list.component.html',
  styleUrls: ['./schema-list.component.scss'],
})
/**
 * Displays the complete schema
 */
export class SchemaListComponent implements OnInit {
  activeFilter: FilterType = 'All'; // Sets default filter to All
  schema: Array<StructType | EnumType | SelectType> = []; // The full schema
  activeSchema: Array<StructType | EnumType | SelectType> = []; // The schema the user sees (search filter + type filter)
  searchSchema: Array<StructType | EnumType | SelectType> = []; // The search filtered schema
  filterSchema: Array<StructType | EnumType | SelectType> = []; // The type filtered schema
  selectedTables: Array<StructType | EnumType | SelectType> = []; // An array of the selected tables from the multiselect
  searchString = ''; // The user search string
  resourceStructs: string[] = []; // A list of all resource StructTypes
  private currentGameId = environment.defaultGame; // The current game ID

  constructor(
    private httpRequestService: HTTPRequestService,
    private router: Router,
    private dataService: DataService,
    private modalService: NgbModal,
    private alertService: AlertService,
  ) {}

  /**
   * Initializes the component by loading the schema
   */
  ngOnInit() {
    this.loadSchema();
  }

  /**
   * Loads the schema and maps explicit typing to the Struct, Enum and Select Types
   */
  loadSchema() {
    if (!this.httpRequestService || !this.httpRequestService.getSchema(this.currentGameId)) {
      return;
    }
    this.httpRequestService.getSchema(this.currentGameId).subscribe({
      next: (data) => {
        const structs: StructType[] = data.structTypes.map((structType) => ({
          ...structType,
          type: 'StructType',
        }));
        const enums: EnumType[] = data.enumTypes.map((enumType) => ({
          ...enumType,
          type: 'EnumType',
        }));
        const selects: SelectType[] = data.selectTypes.map((selectType) => ({
          ...selectType,
          type: 'SelectType',
        }));

        this.resourceStructs = this.dataService.getResourceStructs();

        this.schema = [...structs, ...selects, ...enums].sort(
          (a: StructType | EnumType | SelectType, b: StructType | EnumType | SelectType) => a.name.localeCompare(b.name),
        );
        this.searchSchema = this.schema;
        this.filterSchema = this.schema;
        this.activeSchema = this.schema;
      },
      error: (error) => {
        this.handleError(error);
      },
    });
  }

  /**
   * Creates a new table based on user input
   * @param type
   * @param name
   */
  async createTable(type: string, name: string) {
    if (!name) {
      alert('Please input a valid name');
      return;
    }

    try {
      switch (type) {
        case 'StructType': {
          const structType: StructType = {
            typeId: this.toPascalCase(name),
            name: name,
            fields: [],
            description: '',
            type: 'StructType',
            isResource: false,
            fieldMigrations: undefined,
          };
          const structData = await lastValueFrom(this.httpRequestService.postSchemaStructType(this.dataService.currentGameId, structType));
          await this.router.navigate(['/home/data-model', 'StructType', structData.typeId]);
          break;
        }

        case 'EnumType': {
          const enumType: EnumType = {
            typeId: this.toPascalCase(name),
            name: name,
            options: [],
            description: '',
            type: 'EnumType',
          };
          const enumData = await lastValueFrom(this.httpRequestService.postSchemaEnumType(this.dataService.currentGameId, enumType));
          await this.router.navigate(['/home/data-model', 'EnumType', enumData.typeId]);
          break;
        }

        case 'SelectType': {
          const selectType: SelectType = {
            typeId: this.toPascalCase(name),
            name: name,
            options: [],
            description: '',
            type: 'SelectType',
          };
          const selectData = await lastValueFrom(this.httpRequestService.postSchemaSelectType(this.dataService.currentGameId, selectType));
          await this.router.navigate(['/home/data-model', 'SelectType', selectData.typeId]);
          break;
        }

        default:
          console.warn('No selected modal');
          return;
      }
      this.alertService.showAlert(`Successfully created ${name}`, BootstrapClass.SUCCESS);
      this.modalService.dismissAll();
    } catch (error) {
      this.handleError(error);
    }
  }

  openTable(event: MouseEvent, table: StructType | EnumType | SelectType) {
    event.preventDefault();
    const url = this.router.createUrlTree(['/home/data-model', table.type, table.typeId]).toString();
    if (event.ctrlKey) window.open(location.origin + url, '_blank')?.focus();
    else this.router.navigate(['/home/data-model', table.type, table.typeId]).then();
  }

  /**
   * Searches for tables based on user input
   */
  search() {
    // Filter tables based on table name and search string
    this.searchSchema = this.schema.filter(
      (table: EnumType | StructType | SelectType) => 'name' in table && table.name.toLowerCase().includes(this.searchString.toLowerCase()),
    );

    // Filter tables based on Field name
    if (this.searchString.startsWith('.')) {
      const searchStringNormalized = this.searchString.replace('.', '').toLowerCase();
      const searchSchemaSet = new Set<EnumType | StructType | SelectType>(); // Use a union type for the Set

      for (const table of this.schema) {
        const tableContainsSearchString = (element: Field | SelectTypeOption | string) => {
          let valueToSearch: string;
          if (isField(element)) {
            valueToSearch = element.name;
          } else if (isSelectTypeOption(element)) {
            valueToSearch = element.label;
          } else {
            valueToSearch = element;
          }
          return valueToSearch.toLowerCase().includes(searchStringNormalized);
        };

        let fieldsContainSearchString = false;
        let optionsContainSearchString = false;

        if ('fields' in table) {
          fieldsContainSearchString = table.fields?.some(tableContainsSearchString);
        }
        if ('options' in table) {
          optionsContainSearchString = table.options?.some(tableContainsSearchString);
        }

        if (fieldsContainSearchString || optionsContainSearchString) {
          searchSchemaSet.add(table);
        }
      }

      this.searchSchema = [...searchSchemaSet];
    }

    // Order activeSchema by alphabet and merge filterSchema and searchSchema
    this.activeSchema = this.filterSchema
      .filter((table) => this.searchSchema.includes(table))
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
  }

  /**
   * Filters the schema based on user input
   * @param type
   */
  setFilter(type: FilterType) {
    switch (type) {
      case 'All': {
        this.filterSchema = this.schema;
        break;
      }

      case 'Resource': {
        this.filterSchema = this.schema.filter((table) => this.resourceStructs.includes(table.name));
        break;
      }

      default: {
        this.filterSchema = this.schema.filter((table) => {
          switch (type) {
            case 'StructType':
              return isStructType(table);
            case 'EnumType':
              return isEnumType(table);
            case 'SelectType':
              return isSelectType(table);
            default:
              return false;
          }
        });

        break;
      }
    }

    this.activeFilter = type;

    // Order activeSchema by alphabet and merge filterSchema and searchSchema
    this.activeSchema = this.filterSchema.filter((table) => this.searchSchema.includes(table)).sort((a, b) => a.name.localeCompare(b.name));
  }

  /**
   * Processes the pasted clipboard data to update schema
   * @param jsonDataString
   */
  async processClipboardData(jsonDataString: string) {
    let jsonData: string;
    try {
      jsonData = JSON.parse(jsonDataString);
    } catch (error) {
      this.handleError(error);
      return;
    }

    // Normalize the input to always be an array
    const tableArray = Array.isArray(jsonData) ? jsonData : [jsonData];
    const failedTables: Array<StructType | EnumType | SelectType> = [];
    const modifiedTables: Array<StructType | EnumType | SelectType> = [];
    const createdTables: Array<StructType | EnumType | SelectType> = [];
    let alertString = '';

    // Process each object in the array
    for (const table of tableArray) {
      if (isStructType(table)) {
        try {
          await lastValueFrom(this.httpRequestService.postSchemaStructType(this.dataService.currentGameId, table));
          createdTables.push(table);
          this.alertService.showAlert(`Successfully created ${table.name}`, BootstrapClass.SUCCESS);
        } catch (error) {
          this.handleError(error);
          try {
            await lastValueFrom(this.httpRequestService.updateStructType(this.dataService.currentGameId, table, table.typeId));
            modifiedTables.push(table);
            this.alertService.showAlert(`Successfully updated ${table.name}`, BootstrapClass.SUCCESS);
          } catch (e) {
            this.handleError(e);
            failedTables.push(table);
          }
        }
      }

      if (isEnumType(table)) {
        try {
          await lastValueFrom(this.httpRequestService.postSchemaEnumType(this.dataService.currentGameId, table));
          createdTables.push(table);
          this.alertService.showAlert(`Successfully created ${table.name}`, BootstrapClass.SUCCESS);
        } catch (error) {
          this.handleError(error);
          try {
            await lastValueFrom(this.httpRequestService.updateEnumType(this.dataService.currentGameId, table, table.typeId));
            modifiedTables.push(table);
            this.alertService.showAlert(`Successfully updated ${table.name}`, BootstrapClass.SUCCESS);
          } catch (e) {
            this.handleError(e);
            failedTables.push(table);
          }
        }
      }

      if (isSelectType(table)) {
        try {
          await lastValueFrom(this.httpRequestService.postSchemaSelectType(this.dataService.currentGameId, table));
          createdTables.push(table);
          this.alertService.showAlert(`Successfully created ${table.name}`, BootstrapClass.SUCCESS);
        } catch (error) {
          this.handleError(error);
          try {
            await lastValueFrom(this.httpRequestService.updateSelectType(this.currentGameId, table, table.typeId));
            modifiedTables.push(table);
            this.alertService.showAlert(`Successfully updated ${table.name}`, BootstrapClass.SUCCESS);
          } catch (e) {
            this.handleError(e);
            failedTables.push(table);
          }
        }
      } else {
        alertString += 'Warning: You tried to add a table of an unknown type. \n\n';
      }
    }

    if (createdTables.length > 0) {
      alertString +=
        'Added the following tables: \n' +
        createdTables
          .map((elem) => {
            return `• ${elem.name}`;
          })
          .join('\n');

      for (const table of createdTables) {
        this.schema.push(table);
        this.activeSchema.push(table);
      }
    }

    if (modifiedTables.length > 0) {
      alertString +=
        'Updated the following tables: \n' +
        modifiedTables
          .map((elem) => {
            return `• ${elem.name}`;
          })
          .join('\n');
    }

    if (failedTables.length > 0) {
      alertString +=
        'Failed to create the following tables:\n' +
        failedTables
          .map((elem) => {
            return `• ${elem.name}`;
          })
          .join('\n');

      for (const table of createdTables) {
        this.schema = this.schema.filter((t) => t.typeId !== table.typeId);
      }
    }

    this.selectedTables = [];
    alert(alertString);
  }

  /**
   * Copies a JSON of tables to the clipboard
   */
  copyTables(tables: Array<StructType | EnumType | SelectType>) {
    const tableJSON = JSON.stringify(tables);

    navigator.clipboard
      .writeText(tableJSON)
      .then(() => {
        this.alertService.showAlert(`Successfully copied tables to clipboard`, BootstrapClass.SUCCESS);
        console.log('JSON string copied to clipboard successfully!');
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Opens a modal
   * @param content
   */
  openModal(content: TemplateRef<NgbModalRef>) {
    this.modalService.dismissAll('Closed before opening new modal');
    this.modalService.open(content, { ariaLabelledBy: 'upload-modal-title' }).result.then();
  }

  /**
   * Turns any string into PascalCase
   * @param str
   */
  toPascalCase(str: string): string {
    return (
      str
        // Replace any non-word characters and underscores with a space
        .replace(/[^a-zA-Z0-9]+/g, ' ')
        // Remove any leading or trailing spaces
        .trim()
        // Convert the first character of each word to uppercase
        .replace(/\w+/g, (word) => word.charAt(0).toUpperCase() + word.substr(1))
        // Remove spaces
        .replace(/\s+/g, '')
    );
  }

  /**
   * Handles multiselect checkbox event
   * @param table
   */
  onCheckBoxClick(table: StructType | EnumType | SelectType) {
    if (!this.selectedTables.includes(table)) {
      this.selectedTables.push(table);
    } else {
      this.selectedTables = this.selectedTables.filter((t) => t.typeId !== table.typeId);
    }
  }

  /**
   * Handles multiselect select-all event
   */
  onSelectAllClick() {
    if (this.selectedTables.length === this.activeSchema.length) {
      this.selectedTables = [];
    } else {
      this.selectedTables = this.activeSchema;
    }
  }

  /**
   * Handles any error
   * @param error
   */
  handleError(error: unknown) {
    console.error('An error occurred:', error);

    // Handle HTTP errors
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        alert('No Internet Connection\n\nPlease check your network connection.');
        this.alertService.showAlert('No Internet Connection', BootstrapClass.DANGER);
      } else {
        // Handle Http Error (error.status === 403, 404...)
        alert(`Server Error: ${error.status}\n\n${error.error.message}`);
        this.alertService.showAlert('Server Error', BootstrapClass.DANGER);
      }
    } else if (error instanceof TypeError) {
      // Handle client-side or network error
      alert('Error: A network error occurred\n\nPlease try again later.');
      this.alertService.showAlert('Error: A network error occurred', BootstrapClass.DANGER);
    } else if (error instanceof SyntaxError) {
      // Handle Syntax Errors
      alert(`Syntax Error: \n\n${error.message}`);
      this.alertService.showAlert('Syntax Error', BootstrapClass.DANGER);
    } else if (error instanceof Error) {
      // Handle generic error conditions
      alert(`Error\n\n${error.message}`);
      this.alertService.showAlert('Error', BootstrapClass.DANGER);
    } else {
      // Handle unknown errors
      alert('Unknown Error\n\nAn unknown error occurred. Please contact support.');
      this.alertService.showAlert('Unknown Error', BootstrapClass.DANGER);
    }
  }

  /**
   * Delete multiple tables
   */
  async deleteTables() {
    let alertString = 'Are you sure you want to delete:\n\n';

    alertString += this.selectedTables
      .map((table) => {
        return `• ${table.name}`;
      })
      .join('\n');

    if (!confirm(alertString)) {
      return;
    }

    for (const table of this.selectedTables) {
      if (isStructType(table)) {
        try {
          await lastValueFrom(this.httpRequestService.deleteStructType(this.dataService.currentGameId, table.typeId, false));
          this.activeSchema = this.activeSchema.filter((t) => !(t.typeId === table.typeId && t.type === 'StructType'));
          this.schema = this.schema.filter((t) => !(t.typeId === table.typeId && t.type === 'StructType'));
          this.alertService.showAlert(`Successfully deleted ${table.name}`, BootstrapClass.SUCCESS);
        } catch (error) {
          console.log(error);
          this.alertService.showAlert(`Something went wrong while trying to delete ${table.name}`, BootstrapClass.DANGER);
          if (error instanceof HttpErrorResponse) {
            alert(`${error.error.error}: ${error.error.message}`);
          }
        }

        continue;
      }

      if (isEnumType(table)) {
        try {
          await lastValueFrom(this.httpRequestService.deleteEnumType(this.dataService.currentGameId, table.typeId, false));
          this.activeSchema = this.activeSchema.filter((t) => !(t.typeId === table.typeId && t.type === 'EnumType'));
          this.schema = this.schema.filter((t) => !(t.typeId === table.typeId && t.type === 'EnumType'));
          this.alertService.showAlert(`Successfully deleted ${table.name}`, BootstrapClass.SUCCESS);
        } catch (error) {
          console.log(error);
          this.alertService.showAlert(`Something went wrong while trying to delete ${table.name}`, BootstrapClass.DANGER);
          if (error instanceof HttpErrorResponse) {
            alert(`${error.error.error}: ${error.error.message}`);
          }
        }

        continue;
      }

      if (isSelectType(table)) {
        try {
          await lastValueFrom(this.httpRequestService.deleteSelectType(this.dataService.currentGameId, table.typeId, false));
          this.activeSchema = this.activeSchema.filter((t) => !(t.typeId === table.typeId && t.type === 'SelectType'));
          this.schema = this.schema.filter((t) => !(t.typeId === table.typeId && t.type === 'SelectType'));
          this.alertService.showAlert(`Successfully deleted ${table.name}`, BootstrapClass.SUCCESS);
        } catch (error) {
          console.log(error);
          this.alertService.showAlert(`Something went wrong while trying to delete ${table.name}`, BootstrapClass.DANGER);
          if (error instanceof HttpErrorResponse) {
            alert(`${error.error.error}: ${error.error.message}`);
          }
        }
      }
    }
  }
}
