<div class="modal-header">
  <h5 class="modal-title">Duplication complete</h5>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button"></button>
</div>

<div class="modal-body">
  <p>
    <span class="fw-bold">{{ info.name }}</span> was successfully duplicated!
  </p>
</div>

<div class="modal-footer">
  <button (click)="close()" class="btn btn-secondary" type="button">
    Ok
  </button>

  <button (click)="clickOpenInstance()" class="btn btn-primary" type="button">
    Open
  </button>
</div>
