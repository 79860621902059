import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './_components/home-screen/login/login.component';
import { HomeScreenComponent } from './_components/home-screen/home-screen.component';
import { authGuard } from './_services/authorization/auth.guard';
import { ResourceListComponent } from './_components/home-screen/resource-list/resource-list.component';
import { StructInstanceEditorComponent } from './_components/editors/struct-instance-editor/struct-instance-editor.component';
import { MapEditorComponent } from './_components/editors/map/map-editor/map-editor.component';
import { ModuleEditorComponent } from './_components/editors/module/module-editor/module-editor.component';
import { MissionEditorComponent } from './_components/editors/mission/mission-editor/mission-editor.component';
import { MissionListComponent } from './_components/home-screen/mission-list/mission-list.component';
import { ModuleListComponent } from './_components/home-screen/module-list/module-list.component';
import { SchemaListComponent } from './_components/schema-editor/schema-list/schema-list.component';
import { SchemaTableComponent } from './_components/schema-editor/schema-table/schema-table.component';
import { environment } from '../environments/environment';
import { VariableListComponent } from './_components/home-screen/variable-list/variable-list.component';
import { VariableEditorComponent } from './_components/editors/variable/variable-editor/variable-editor.component';
import { MaintenanceScreenComponent } from './_components/maintenance-screen/maintenance-screen.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    title: `${environment.defaultGame.charAt(0).toUpperCase() + environment.defaultGame.slice(1)} - CAS`,
    component: HomeScreenComponent,
    canActivate: [authGuard],
    children: [
      { path: 'MissionInfo', component: MissionListComponent },
      { path: 'mission/:missionInfoUid', component: MissionEditorComponent, canActivate: [authGuard] },
      {
        path: 'Module',
        component: ModuleListComponent,
      },
      {
        path: 'data-model',
        component: SchemaListComponent,
      },
      {
        path: "maintenance",
        component: MaintenanceScreenComponent
      },
      {
        path: 'data-model/:type/:typeId',
        component: SchemaTableComponent,
      },
      { path: 'module/:moduleUid', component: ModuleEditorComponent, canActivate: [authGuard] },
      { path: 'Map/:mapUid', component: MapEditorComponent },
      { path: 'Variable', component: VariableListComponent },
      { path: 'variable/:variableRef', component: VariableEditorComponent },
      { path: ':resource', component: ResourceListComponent },
      { path: ':resource/:resourceUid', component: StructInstanceEditorComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
