<div [ngStyle]="{'background-color': backgroundColor}" class="title" data-testid="title">{{ data.label }}</div>

<div
  *ngFor="let output of data.outputs | keyvalue"
  [attr.data-testid]="'output-'+output.key"
  class="output"
>
  <div class="output-title" data-testid="output-title">
    {{ outputLabels[output.key] }}
  </div>
  <div
    [data]="{type: 'socket', side: 'output', key: output.key, nodeId: data.id, payload: outputSockets[output.key] }"
    [emit]="emit"
    class="output-socket"
    data-testid="output-socket"
    refComponent
  ></div>
</div>

<div
  *ngFor="let input of data.inputs | keyvalue"
  [attr.data-testid]="'input-'+input.key"
  class="input"
>
  <div
    [data]="{type: 'socket', side: 'input', key: input.key, nodeId: data.id, payload: inputSockets[input.key] }"
    [emit]="emit"
    class="input-socket"
    data-testid="input-socket"
    refComponent
  ></div>
  <div class="input-title" data-testid="input-title">
    {{ inputLabels[input.key] }}
  </div>
  <div
    [data]="{type: 'control', payload: input.value }"
    [emit]="emit"
    [style.display]="input.value && input.value ? '' : 'none'"
    class="input-control"
    data-testid="input-control"
    refComponent
  ></div>
</div>
