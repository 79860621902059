import { NodeEditor } from 'rete';
import { Injector } from '@angular/core';
import { Area2D, AreaExtensions, AreaPlugin } from 'rete-area-plugin';
import { ConnectionPlugin, Presets as ConnectionPresets } from 'rete-connection-plugin';
import { AutoArrangePlugin, Presets as ArrangePresets } from 'rete-auto-arrange-plugin';

import { AngularArea2D, AngularPlugin, Presets as AngularPresets } from 'rete-angular-plugin/16';
import { SelectorEntity } from 'rete-area-plugin/_types/extensions/selectable';
import { ActivityNode, Schemes } from './nodes';
import { BehaviorSubject } from 'rxjs';
import { addCustomBackground } from './custom-background';
import { ActivityNodeComponent } from './activity-node/activity-node.component';
import { SocketComponent } from './socket/socket.component';
import { KennisNodeComponent } from './kennis-node/kennis-node.component';
import { ConnectionComponent } from './connection/connection.component';
import { getDOMSocketPosition } from 'rete-render-utils';

export type AreaExtra = Area2D<Schemes> | AngularArea2D<Schemes>;

export class MySelector<E extends SelectorEntity> extends AreaExtensions.Selector<E> {
  private nodeSelected = new BehaviorSubject<Pick<E, 'label' | 'id'> | undefined>(undefined);
  nodeSelected$ = this.nodeSelected.asObservable();
  private ctrlPressed = false;
  private shiftPressed = false;

  constructor() {
    super();
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Control') {
        this.ctrlPressed = true;
      } else if (e.key === 'Shift') {
        this.shiftPressed = true;
      }
    });
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Control') {
        this.ctrlPressed = false;
      } else if (e.key === 'Shift') {
        this.shiftPressed = false;
      }
    });
  }

  override pick(entity: Pick<E, 'label' | 'id'>): void {
    if (this.ctrlPressed || this.shiftPressed) {
      super.pick(entity);
    } else {
      super.unselectAll();
      super.pick(entity);
      this.nodeSelected.next(entity);
    }
  }

  override unselectAll(force?: boolean): void {
    if (force) return super.unselectAll();
    if (!this.nodeSelected.value && !this.ctrlPressed && !this.shiftPressed) super.unselectAll();
  }
}

export async function createEditor(
  container: HTMLElement,
  injector: Injector,
  turnOnDraggable: boolean,
): Promise<{
  editor: NodeEditor<Schemes>;
  area: AreaPlugin<Schemes, AreaExtra>;
  destroy: () => void;
  selector: MySelector<SelectorEntity>;
  layout: () => void;
  zoomAtAllNodes: () => void;
}> {
  const editor = new NodeEditor<Schemes>();
  const area = new AreaPlugin<Schemes, AreaExtra>(container);

  const angularRender = new AngularPlugin<Schemes, AreaExtra>({ injector });
  const selector = new MySelector();
  const arrange = new AutoArrangePlugin<Schemes, never>();

  AreaExtensions.selectableNodes(area, selector, { accumulating: AreaExtensions.accumulateOnCtrl() });

  AreaExtensions.restrictor(area, {
    scaling: () => ({ min: 0.2, max: 2 }),
  });

  angularRender.addPreset(
    AngularPresets.classic.setup({
      customize: {
        node(context) {
          if (context.payload instanceof ActivityNode) {
            return ActivityNodeComponent;
          }
          return KennisNodeComponent;
        },
        socket() {
          return SocketComponent;
        },
        connection() {
          return ConnectionComponent;
        },
      },
      socketPositionWatcher: getDOMSocketPosition({
        offset({ x, y }, nodeId, side, _) {
          return {
            x: x + 7 * (side === 'input' ? -1 : 1),
            y: y + 1,
          };
        },
      }),
    }),
  );
  arrange.addPreset(ArrangePresets.classic.setup());

  editor.use(area);
  area.use(angularRender);
  area.use(arrange);

  if (turnOnDraggable) {
    const connection = new ConnectionPlugin<Schemes, AreaExtra>();
    connection.addPreset(ConnectionPresets.classic.setup());
    area.use(connection);
  }

  addCustomBackground(area);

  AreaExtensions.simpleNodesOrder(area);

  return {
    destroy: () => area.destroy(),
    editor,
    area,
    selector,
    layout: async () => {
      await arrange.layout();
      await AreaExtensions.zoomAt(area, editor.getNodes());
    },
    zoomAtAllNodes: async () => {
      await AreaExtensions.zoomAt(area, editor.getNodes());
    },
  };
}
