export const environment = {
  production: false,
  authServerHost: 'https://api.electromagnetism.dev.gametailors.com/auth/',
  casHost: 'https://api.electromagnetism.dev.gametailors.com/cas',
  // TODO: Do not hardcode this
  dataPackage: 'dev_datapackage-824b700b-2198-4b9f-8e7b-b6d2a7acf605',
  defaultGame: 'electro',
  aspectRatio: { width: 1920, height: 1080 },
  playGameUrl: 'https://play.electromagnetism.dev.gametailors.com/',

  version_latestBuildNumber: '727',
  version_latestCommitHash: '3160f8b52cb6338cbbe0a472abca1ec130b7f086',
  version_latestDate: '16/09/2024 13:46',
};
