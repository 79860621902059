<div class="container">
  <button (click)="addStructInstance()" *ngIf="this.data && !this.data.value" class="btn btn-primary">Add</button>

  <div *ngIf="currentResource" [ngClass]="{'card': editorType !== 'SeamlessInline'}">
    <div *ngIf="editorType !== 'SeamlessInline'" [ngbPopover]="structTypeDescription"
         class="card-header justify-content-between d-flex" triggers="hover">
      <div class="align-content-center">
        <i *ngIf="icon" [class]="icon"></i> {{ resourceStructType | prettifyName }}
      </div>
      <button (click)="onDelete()" *ngIf="showDeleteButton" class="btn btn-danger">
        <i class="bi bi-trash"></i>
      </button>
    </div>

    <!-- TODO: Create a better solution for the color picker's z-index instead of this hardcoding -->
    <div *ngFor="let fieldComponent of currentResourceFieldComponents"
         class="{{fieldComponent.data.fieldId === 'media' || fieldComponent.data.fieldId === 'color' || fieldComponent.data.fieldId === 'background' ? 'position-relative z-1' : ''}}">
      <app-field [fieldComponent]="fieldComponent"/>
    </div>

    <ng-content select="[slot=custom]"></ng-content>
  </div>
</div>
