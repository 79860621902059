import { Component, Input, OnInit } from '@angular/core';
import { SimpleStructInstance, SubcomponentService } from '../../../../_services/data-management/subcomponent.service';
import { DataService } from '../../../../_services/data-management/data.service';
import { FieldData } from '../../../../models/data/FieldData';
import { Resource } from '../../../../models/data/Resource';
import { isStructOrRef } from '../../../../_services/utils';
import { DynamicFieldComponent } from '../../dynamic-field.component';

@Component({
  selector: 'app-chat-field',
  templateUrl: './chat-field.component.html',
  styleUrls: ['./chat-field.component.scss'],
})
export class ChatFieldComponent implements OnInit, DynamicFieldComponent<FieldData<string[]> | undefined> {
  @Input() data: FieldData<string[]> | undefined;

  chatCharacters: Resource[] = [];
  messages: SimpleStructInstance[] = [];
  mediaUrls: Record<string, string> = {};

  protected readonly isStructOrRef = isStructOrRef;

  constructor(
    private dataService: DataService,
    private subCompService: SubcomponentService,
  ) {}

  async ngOnInit() {
    this.chatCharacters = this.dataService.getSelectTypeResource('ChatCharacterType');
    if (this.data && this.data.value) {
      this.messages = await this.subCompService.convertArrayData(this.data);
    }
  }

  async addMessage(characterName: string, messageTypeId: string) {
    const messageStruct = this.dataService.getStructType(messageTypeId);
    if (!this.data) throw new Error('Data not found');
    this.messages.push(
      await this.subCompService.addSubStruct(this.data.dataInstanceUid, this.data.fieldId, this.data.value, messageStruct),
    );

    // TODO: The dropdown is initialized with the first value, but the value is not set in the data instance
    //  That's why for now we copy the field, delete it, set the value and then add it again.
    const messageField = this.messages[this.messages.length - 1];

    this.messages.splice(this.messages.length - 1, 1);
    await this.changeMessageCharacter(messageField, characterName);
    this.messages.push(messageField);
  }

  async changeMessageCharacter(messageField: SimpleStructInstance, characterName: string) {
    // Get the character resource
    const character = this.chatCharacters.find((character) => character.name === characterName);
    if (!character) throw new Error('Character not found');
    // Update the character field value
    await this.dataService.updateFieldValue(messageField.uid, 'character', character.value);
  }

  changeImageOfMessage(imageUrl: { name: string; url: string }, messageUid: string) {
    this.mediaUrls[messageUid] = imageUrl.url;
  }

  isLastMessage(message: SimpleStructInstance) {
    return this.subCompService.isLastField(message, this.messages);
  }

  isFirstMessage(message: SimpleStructInstance) {
    return this.subCompService.isFirstField(message, this.messages);
  }

  async moveMessageUp(message: SimpleStructInstance) {
    if (!this.data) throw new Error('Data not found');
    const dataInstance = await this.dataService.getDataInstance(this.data.dataInstanceUid);
    if (!dataInstance) throw new Error('Data instance not found');
    this.subCompService.moveFieldUp(message, this.messages, dataInstance, this.data.fieldId);
  }

  async moveMessageDown(message: SimpleStructInstance) {
    if (!this.data) throw new Error('Data not found');
    const dataInstance = await this.dataService.getDataInstance(this.data.dataInstanceUid);
    if (!dataInstance) throw new Error('Data instance not found');
    this.subCompService.moveFieldDown(message, this.messages, dataInstance, this.data.fieldId);
  }

  async removeMessage(message: SimpleStructInstance) {
    if (!this.data) throw new Error('Data not found');
    const dataInstance = await this.dataService.getDataInstance(this.data.dataInstanceUid);
    if (!dataInstance) throw new Error('Data instance not found');
    await this.subCompService.removeSubStruct(message, this.messages, dataInstance, this.data.fieldId);
    this.data.value.splice(this.data.value.indexOf(message.uid), 1);
  }

  isSentByPlayer(message: SimpleStructInstance) {
    return message.fields['character'].value === this.chatCharacters.find((character) => character.name === 'Player')?.value;
  }

  isSentByNPC(message: SimpleStructInstance) {
    return message.fields['character'].value === this.chatCharacters.find((character) => character.name === 'NPC')?.value;
  }

  isSentByGuide(message: SimpleStructInstance) {
    return message.fields['character'].value === this.chatCharacters.find((character) => character.name === 'Guide')?.value;
  }

  convert(data: FieldData<unknown>) {
    return data as FieldData<string>;
  }
}
