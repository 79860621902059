import { Component, Input } from '@angular/core';
import { FieldData } from '../../../../models/data/FieldData';
import { DynamicFieldComponent } from '../../dynamic-field.component';

@Component({
  selector: 'app-video-field',
  templateUrl: './video-field.component.html',
  styleUrls: ['./video-field.component.scss'],
})
export class VideoFieldComponent implements DynamicFieldComponent<FieldData<string> | undefined> {
  @Input() data: FieldData<string> | undefined;

  currentURL = '';
  currentName?: string;
  currentMimeType?: string = 'video/mp4';

  changeVideo(videoUrl: { name: string; url: string; mimeType: string }) {
    this.currentURL = videoUrl.url;
    this.currentName = videoUrl.name;
    this.currentMimeType = videoUrl.mimeType;
  }
}
