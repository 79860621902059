<div class="vid-editor">

  <video *ngIf="currentMimeType?.includes('video') && currentURL" [src]="currentURL" controls loop muted>
  </video>

  <div
    *ngIf="currentURL && !currentMimeType?.includes('video')"
    class="text-muted"
  >
    No preview available.
  </div>

  <div *ngIf="!currentURL" class="text-muted">
    No video selected yet.
  </div>

  <app-file-upload
    (newFileSelected)="changeVideo($event)"
    [currentURL]="currentURL"
    [data]="data"
    [fileType]="'video'"
  />
</div>

