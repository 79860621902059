import { FieldEditor } from '../schema/FieldEditor';

export interface FieldData<T> {
  dataInstanceUid: string;
  fieldId: string;
  fieldType: string;
  fieldEditor?: FieldEditor;
  name: string;
  description: string | undefined;
  value: T;
}

export default class {
  static transform(data: FieldData<string | string[] | unknown>) {
    if (!Array.isArray(data.value)) return data as FieldData<string>;
    data.value = data.value.at(0) as string;
    return data as FieldData<string>;
  }
}
