import { Injectable } from '@angular/core';
import { Vector2 } from '../models/types/Vector2';
import { SelectTypeOption } from '../models/schema/SelectTypeOption';
import { Resource } from '../models/data/Resource';
import { Field } from '../models/schema/Field';

@Injectable()
export class ConvertService {
  /*
   * Stringifies the value of a field if necessary.
   * If it can not be stringified directly, it will use the needed conversion function.
   */
  stringify(value: unknown, field: Field): string {
    // If the field type is a Vector2 and the value is not a string, we need to convert the value
    if (field.type === 'Vector2' && typeof value !== 'string') return this.convertVector2ToString(value as Vector2);
    else if (value && typeof value !== 'string') return JSON.stringify(value);
    else return value as string;
  }

  /*
   * Parses the value of a field if necessary.
   * If it can now be parsed directly, it will use the needed conversion function.
   */
  parse(value: string, field: Field) {
    try {
      // If the field type is a Vector2, we need to convert the value
      if (field.type === 'Vector2') return this.convertToVector2(value);
      // Else if the field is not a string nor a reference, we need to parse the value
      if (!this.isFieldValueString(field.type)) return JSON.parse(value);
      return value;
    } catch (e) {
      console.error(`Could not parse value "${value}" of type "${field.type}"`);
      throw e;
    }
  }

  /*
   * Converts a string to a Vector2
   * If the string is not a Vector2, it will return {x: 0, y: 0}
   */
  convertToVector2(value: string): Vector2 {
    if (!value) return { x: 0, y: 0 };

    const x = value.split(';')[0];
    const y = value.split(';')[1];

    // If x or y is not a number, return undefined
    if (isNaN(+x) || isNaN(+y)) {
      console.error(`Could not convert to Vector2: "${value}". Returning {x: 0, y: 0}`);
      return { x: 0, y: 0 };
    }

    return { x: +x, y: +y };
  }

  /*
   * Converts a Vector2 to a string
   * If the Vector2 is not valid, it will return "0;0"
   */
  convertVector2ToString(vector: Vector2): string {
    if (isNaN(vector.x) || isNaN(vector.y)) {
      console.warn('Could not convert Vector2 to string: ' + vector + ". Returning '0;0'");
      return '0;0';
    }
    return vector.x + ';' + vector.y;
  }

  convertSelectTypeOption(selectTypeOption: SelectTypeOption): Resource {
    return { value: selectTypeOption.optionId, name: selectTypeOption.label };
  }

  convertEnumTypeOption(enumTypeOption: string): Resource {
    return { value: enumTypeOption, name: enumTypeOption };
  }

  isFieldValueString(fieldType: string) {
    // TODO: Do regex here
    return (
      fieldType === 'string' ||
      fieldType.startsWith('StructRef') ||
      fieldType.startsWith('Struct') ||
      fieldType.startsWith('EnumRef') ||
      fieldType.startsWith('Enum') ||
      fieldType.startsWith('ImageRef') ||
      fieldType.startsWith('AudioRef') ||
      fieldType.startsWith('VideoRef') ||
      fieldType.startsWith('FileRef') ||
      fieldType.startsWith('Icon') ||
      fieldType.startsWith('Select') ||
      fieldType.startsWith('Color') ||
      fieldType.startsWith('VariableRef')
    );
  }
}
