import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../_services/data-management/data.service';
import { ConfirmationModalService } from '../../../_services/UI-elements/confirmation-modal.service';
import { environment } from '../../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingScreenService } from '../../../_services/UI-elements/loading-screen.service';
import { AlertService } from '../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export type MissionInfo = {
  missionInfoUid: string;
  missionUid: string;
  name: string;
  description: string;
};

@Component({
  selector: 'app-mission-list',
  templateUrl: './mission-list.component.html',
})
export class MissionListComponent {
  @ViewChild('duplicationCompleteModal') duplicationCompleteModal!: NgbModalRef;

  missionInfos: MissionInfo[] = [];
  structTypeDescription = '';

  protected newMissionInfo?: MissionInfo;
  protected readonly environment = environment;

  constructor(
    private dataService: DataService,
    private router: Router,
    private confirmService: ConfirmationModalService,
    private loadingScreenService: LoadingScreenService,
    private alertService: AlertService,
    private modalService: NgbModal,
  ) {
    this.getMissionInfos().then();
  }

  getMissionInfos() {
    return this.loadingScreenService.show(async () => {
      const missionInfos = await this.dataService.getMissionInfos();

      // For each mission, get the mission info data instance and add the name and description to the missionInfos array
      this.missionInfos = missionInfos.map((missionInfo) => ({
        missionInfoUid: missionInfo.uid,
        missionUid: missionInfo.fieldValues.find((fv) => fv.field === 'mission')?.value as string,
        name: missionInfo.fieldValues.find((fv) => fv.field === 'name')?.value as string,
        description: missionInfo.fieldValues.find((fv) => fv.field === 'description')?.value as string,
      }));

      this.sortMissions();

      this.structTypeDescription = this.dataService.getStructType('MissionInfo')?.description;
    });
  }

  sortMissions() {
    this.missionInfos.sort((a, b) => {
      if (!a.name) return 1;
      if (!b.name) return -1;
      return a.name.localeCompare(b.name);
    });
  }

  openMission(missionInfoUid: string) {
    this.router.navigate(['/home/mission', missionInfoUid]).then();
  }

  createMission() {
    return this.loadingScreenService.show(async () => {
      const newMissionInfo = await this.dataService.initStruct('MissionInfo');

      // Initialize the mission data instance, and reference it in the mission info data instance
      for (const fieldValue of newMissionInfo.fieldValues) {
        if (fieldValue.field === 'mission') {
          const newMission = await this.dataService.initStruct('Mission');
          fieldValue.value = newMission.uid;
          await this.dataService.updateDataInstance(newMissionInfo);
        }
      }

      this.openMission(newMissionInfo.uid);
    });
  }

  async deleteMission(missionInfoUid: string, force = false): Promise<unknown> {
    if (!force) {
      // Open a confirmation modal
      const confirmed = await firstValueFrom(this.confirmService.confirm('Are you sure you want to delete this mission?'));
      if (!confirmed) return;
    }

    return await this.loadingScreenService.show(async () => {
      try {
        const mission = await this.dataService.getDataInstanceFromDB(
          this.dataService.currentGameId,
          this.dataService.currentDataPackage,
          'mission',
          missionInfoUid,
        );

        await this.dataService.deleteDataInstance(mission, {
          throwError: true,
          force,
        });

        this.missionInfos.splice(
          this.missionInfos.findIndex((r) => r.missionInfoUid === missionInfoUid),
          1,
        );
        return;
      } catch (e) {
        if (
          !force &&
          e instanceof HttpErrorResponse &&
          e.status === 409 &&
          confirm(
            'This mission is referenced in other places. Are you sure you want to delete it? This is a destructive action and cannot be undone.',
          )
        ) {
          return this.deleteMission(missionInfoUid, true);
        }

        throw e;
      }
    });
  }

  downloadMission(missionInfoUid: string) {
    return this.loadingScreenService.show(async () => this.dataService.downloadMission(missionInfoUid));
  }

  duplicateMission(missionInfoUid: string) {
    return this.loadingScreenService.show(async () => {
      try {
        const dataInstance = await this.dataService.duplicateDataInstance(missionInfoUid);

        this.newMissionInfo = {
          missionInfoUid: dataInstance.uid,
          missionUid: dataInstance.fieldValues.find((fv) => fv.field === 'mission')?.value as string,
          name: (dataInstance.fieldValues.find((fv) => fv.field === 'name')?.value as string) + ' (Copy)',
          description: dataInstance.fieldValues.find((fv) => fv.field === 'description')?.value as string,
        };

        this.missionInfos.push(this.newMissionInfo);
        this.sortMissions();

        this.modalService.dismissAll('Closed before opening new modal');
        this.modalService.open(this.duplicationCompleteModal, {
          ariaLabelledBy: 'duplication-success-modal',
          centered: true,
        });

        return;
      } catch (e) {
        this.alertService.showAlert('Failed to duplicate mission!', BootstrapClass.DANGER);
        throw e;
      }
    });
  }

  copyResourceIdToClipboard(resourceUid: string) {
    navigator.clipboard
      .writeText(resourceUid)
      .then(() => {
        this.alertService.showAlert('Resource ID copied to clipboard: ' + resourceUid, BootstrapClass.SUCCESS);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.alertService.showAlert('Failed to copy Resource ID!', BootstrapClass.DANGER);
      });
  }
}
