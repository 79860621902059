import { FieldEditor } from './FieldEditor';

export interface Field {
  fieldId: string;
  type: string;
  name: string;
  description: string | undefined;
  required: boolean;
  defaultValue: string | boolean | undefined | null;
  fieldEditor: FieldEditor | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isField(obj: any): obj is Field {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.fieldId === 'string' &&
    typeof obj.type === 'string' &&
    typeof obj.name === 'string' &&
    (typeof obj.description === 'string' || obj.description === undefined) &&
    typeof obj.required === 'boolean' &&
    (typeof obj.defaultValue === 'string' ||
      typeof obj.defaultValue === 'boolean' ||
      obj.defaultValue === undefined ||
      obj.defaultValue === null)
    // todo: fix fieldeditor check
    // (obj.fieldEditor === undefined || isFieldEditor(obj.fieldEditor))
  );
}
