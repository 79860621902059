<div class="p-4 container-fluid">
  <div class="card shadow-sm">
    <div class="card-header">
      <h2>Datamodel editor</h2>
    </div>

    <div class="card-body d-flex flex-column gap-3">
      <button [routerLink]="'/home/maintenance'" class="btn btn-primary" style="width: fit-content">
        Go to maintenance page
      </button>

      <div class="flex flex-column">
        <div class="menu">
          <input
            (input)="search()"
            [(ngModel)]="searchString"
            class="form-control w-25"
            placeholder="Search"
            type="text"
          />

          <div class="d-flex gap-1">
            <button
              (click)="copyTables(this.selectedTables)"
              [disabled]="selectedTables.length === 0"
              class="btn btn-secondary"
              title="Copy selected tables"
            >
              <i class="bi bi-copy"></i>
            </button>

            <button
              (click)="deleteTables()"
              [disabled]="selectedTables.length === 0"
              class="btn btn-danger"
              title="Delete selected tables"
            >
              <i class="bi bi-trash"></i>
            </button>

            <div class="dropdown">
              <button
                aria-expanded="false"
                aria-haspopup="true"
                class="btn btn-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                id="dropdownMenuButton"
                title="New"
                type="button"
              >
                <i class="bi bi-plus-lg"></i>
              </button>

              <div aria-labelledby="dropdownMenuButton" class="dropdown-menu">
                <button (click)="openModal(createModal)" class="dropdown-item">Table</button>
                <button (click)="openModal(createWithJSONModal)" class="dropdown-item">Table(s) from clipboard</button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex gap-1 mt-2">
          <button (click)="setFilter('All')" [class.active]="activeFilter === 'All'" class="filter all">
            All
          </button>
          <button
            (click)="setFilter('StructType')"
            [class.active]="activeFilter === 'StructType'"
            class="filter structtype"
          >
            StructType
          </button>
          <button
            (click)="setFilter('SelectType')"
            [class.active]="activeFilter === 'SelectType'"
            class="filter selecttype"
          >
            SelectType
          </button>
          <button (click)="setFilter('EnumType')" [class.active]="activeFilter === 'EnumType'" class="filter enumtype">
            EnumType
          </button>
          <button (click)="setFilter('Resource')" [class.active]="activeFilter === 'Resource'" class="filter resource">
            Resource
          </button>
        </div>
      </div>

      <div>
        <h5>{{ selectedTables.length }} selected</h5>

        <table class="table border table-hover">
          <thead>
          <tr>
            <th class="w-fit">
              <input
                (change)="onSelectAllClick()"
                [checked]="selectedTables.length === activeSchema.length"
                class="form-check-input"
                type="checkbox"
              />
            </th>

            <th>Name</th>
            <th>Description</th>
            <th>Type</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let table of activeSchema">
            <td class="w-fit">
              <input
                (change)="onCheckBoxClick(table)"
                [checked]="selectedTables.includes(table)"
                class="form-check-input"
                type="checkbox"
              />
            </td>

            <td (click)="openTable($event, table)" class="cursor-pointer">
              {{ table.name }}
            </td>

            <td (click)="openTable($event, table)" class="cursor-pointer">
              {{ table.description }}
            </td>

            <td class="text-center align-middle center">
              <div class="d-flex h-100 flex-nowrap gap-2">
                <div class="type active {{ table.type.toLowerCase() }}">
                  {{ table.type }}
                </div>

                <div *ngIf="resourceStructs.includes(table.name)" class="type resource active">
                  Resource
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #createModal let-modal>
  <div class="modal-header">Create new</div>
  <div class="modal-body d-flex flex-column gap-3">
    <select #typeSelect class="form-select">
      <option selected value="StructType">
        StructType
      </option>
      <option value="EnumType">
        EnumType
      </option>
      <option value="SelectType">
        SelectType
      </option>
    </select>

    <div>
      <div class="pb-1">Name</div>
      <input #nameInput class="form-control" type="text" />
    </div>

    <div class="modal-footer">
      <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary">
        Cancel
      </button>
      <button (click)="createTable(typeSelect.value, nameInput.value)" class="btn btn-primary">
        Create
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createWithJSONModal let-modal>
  <div class="p-3">
    <div class="modal-header">From clipboard</div>
    <div class="modal-body">
      <textarea
        #jsonArea
        class="form-control"
        placeholder="Paste your table(s)..."
        style="height: 400px"
        type="text"
      ></textarea>
    </div>

    <div class="modal-footer">
      <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary">Cancel</button>
      <button (click)="processClipboardData(jsonArea.value)" class="btn btn-primary">Save</button>
    </div>
  </div>
</ng-template>
