import { isSelectTypeOption, SelectTypeOption } from './SelectTypeOption';

export interface SelectType {
  typeId: string;
  name: string;
  description: string | undefined;
  options: SelectTypeOption[];
  type: 'SelectType';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSelectType(obj: any): obj is SelectType {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.typeId === 'string' &&
    typeof obj.name === 'string' &&
    (typeof obj.description === 'string' || obj.description === undefined) &&
    Array.isArray(obj.options) &&
    obj.options.every((option: unknown) => isSelectTypeOption(option)) && // Assuming you have a type guard for SelectTypeOption
    obj.type === 'SelectType'
  );
}
