<div class="container d-flex flex-column gap-2 py-4">
  <!--    create a table for each mission in missions. With columns mission.uid, mission.name, mission.description-->
  <div class="resource-title">
    <h1 [ngbPopover]="structTypeDescription" class="text-center text-white pb-2 drop-shadow"
        triggers="hover">Missions</h1>
    <button (click)="createMission()" class="btn btn-primary"><i class="bi bi-plus-circle"></i></button>
  </div>
  <table class="table table-striped table-hover">
    <thead>
    <tr>
      <th scope="col">Mission Name</th>
      <th scope="col">Mission Description</th>
      <th scope="col">Mission ID</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let missionInfo of missionInfos">
      <td (click)="openMission(missionInfo.missionInfoUid)" class="cursor-pointer">{{ missionInfo.name }}</td>
      <td (click)="openMission(missionInfo.missionInfoUid)" class="cursor-pointer">{{ missionInfo.description }}</td>
      <td (click)="openMission(missionInfo.missionInfoUid)"
          class="cursor-pointer font-monospace">{{ missionInfo.missionInfoUid }}
      </td>
      <td>
        <div class="d-flex gap-1 justify-content-end">
          <button
            (click)="openMission(missionInfo.missionInfoUid)"
            class="btn btn-no-space btn-outline-dark align-right"
            ngbPopover="Edit"
            triggers="hover"
          >
            <span class="bi bi-pencil-square"></span>
          </button>

          <button (click)="copyResourceIdToClipboard(missionInfo.missionInfoUid)"
                  class="btn btn-no-space btn-outline-dark align-right"
                  ngbPopover="Copy ID" triggers="hover"
          >
            <span class="bi bi-code"></span>
          </button>

          <button
            (click)="downloadMission(missionInfo.missionInfoUid)"
            class="btn btn-no-space btn-outline-dark align-right"
            ngbPopover="Download JSON"
            triggers="hover"
          >
            <i class="bi bi-download"></i>
          </button>

          <button
            (click)="duplicateMission(missionInfo.missionInfoUid)"
            class="btn btn-no-space btn-outline-dark align-right"
            ngbPopover="Duplicate / clone"
            triggers="hover"
          >
            <i class="bi bi-copy"></i>
          </button>

          <button
            (click)="deleteMission(missionInfo.missionInfoUid)"
            class="btn btn-no-space btn-outline-dark align-right"
            ngbPopover="Delete"
            triggers="hover"
          >
            <span class="bi bi-trash"></span>
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="text-center">
    <button (click)="createMission()" class="btn btn-primary">
      <i class="bi bi-plus-circle"></i>
    </button>
  </div>
</div>

<ng-template #duplicationCompleteModal let-modal>
  <app-duplication-complete-modal
    (openInstance)="openMission(this.newMissionInfo!.missionInfoUid!)"
    [info]="{ name: this.newMissionInfo!.name! }"
    [modal]="modal"
  />
</ng-template>
