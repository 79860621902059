import { Directive, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { DynamicFieldObject } from '../dynamic-field.object';

@Directive({
  selector: '[appField]',
})
export class FieldDirective implements OnChanges {
  @Input('appField') fieldComponent: DynamicFieldObject | undefined;

  constructor(private viewRef: ViewContainerRef) {}

  ngOnChanges(_changes: SimpleChanges) {
    this.loadComponent();
  }

  loadComponent() {
    if (!this.fieldComponent) {
      return;
    }

    this.viewRef.clear();
    const componentRef = this.viewRef.createComponent(this.fieldComponent.component as DynamicFieldObject['component']);

    componentRef.setInput('data', this.fieldComponent.data);
    if ('editorType' in componentRef.instance) {
      componentRef.setInput('editorType', this.fieldComponent.component.editorType);
    }
  }
}
