import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BootstrapClass } from '../../models/types/BootstrapClass';
import { AlertData } from '../../models/data/AlertData';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alertSubject = new BehaviorSubject<AlertData[]>([]);

  alert$: Observable<AlertData[]> = this.alertSubject.asObservable();

  showAlert(message: string, bootstrapClass: BootstrapClass, timeout = 3000) {
    const alert: AlertData = { message, classType: 'alert-' + bootstrapClass };
    this.alertSubject.next([...this.alertSubject.getValue(), alert]);

    setTimeout(() => {
      this.removeAlert(alert);
    }, timeout);
  }

  error(message: string) {
    this.showAlert(message, BootstrapClass.DANGER, 5000);
  }

  info(message: string) {
    this.showAlert(message, BootstrapClass.INFO, 5000);
  }

  success(message: string) {
    this.showAlert(message, BootstrapClass.SUCCESS);
  }

  removeAlert(alert: AlertData) {
    this.alertSubject.next(this.alertSubject.getValue().filter((x) => x !== alert));
  }
}
