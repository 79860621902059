import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../../../../_services/data-management/data.service';
import { FlowchartNode } from '../../../../models/data/FlowchartNode';
import { DataInstance } from '../../../../models/data/DataInstance';
import { Resource } from '../../../../models/data/Resource';
import { environment } from '../../../../../environments/environment';
import { sleep } from '../../../../_services/utils';
import { FieldData } from '../../../../models/data/FieldData';
import { LoadingScreenService } from '../../../../_services/UI-elements/loading-screen.service';

@Component({
  selector: 'app-module-editor',
  templateUrl: './module-editor.component.html',
  styleUrls: ['./module-editor.component.scss'],
})
export class ModuleEditorComponent implements OnInit, OnDestroy {
  module?: DataInstance;
  moduleFieldData?: FieldData<string>;
  moduleId?: string;
  moduleName?: string;
  loading = false;
  kennisNodeUid?: string;
  currentKennisNode?: FlowchartNode = undefined;
  kennisNodes: FlowchartNode[] = [];
  kennisNodeTypes: Resource[] = [];

  protected readonly environment = environment;

  private routeSub?: Subscription;
  private routerSubscription?: Subscription;
  private currentKennisNodeSubscription?: Subscription;
  private kennisNodesSubscription?: Subscription;
  private moduleUid?: string;

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingScreenService: LoadingScreenService,
  ) {}

  ngOnInit(): void {
    this.routeSub = this.activatedRoute.params.subscribe(async (params) => {
      this.moduleUid = params['moduleUid'];
      this.loading = true;
      await this.loadModule();
      if (this.moduleUid)
        this.moduleFieldData = {
          dataInstanceUid: '',
          fieldId: '',
          fieldType: '',
          name: '',
          description: '',
          value: this.moduleUid,
        };
      this.loading = false;
      this.kennisNodeTypes = this.dataService.getSelectTypeResource('KennisNodeType');
    });

    this.routerSubscription = this.activatedRoute.queryParams.subscribe(async (params) => {
      this.kennisNodeUid = params['kennisNode'];
      while (this.loading) {
        await sleep(100);
      }
      this.currentKennisNode = this.kennisNodes.find((a) => a.dataInstanceUid === this.kennisNodeUid);
      if (this.kennisNodeUid) {
        this.dataService.currentActivityChanged.next(this.currentKennisNode);
      } else {
        this.dataService.currentActivityChanged.next(undefined);
      }
    });

    this.kennisNodesSubscription = this.dataService.nodesUpdated$.subscribe((kennisNodes: FlowchartNode[]) => {
      this.kennisNodes = kennisNodes;
    });

    this.currentKennisNodeSubscription = this.dataService.currentNodeChanged$.subscribe((kennisNode) => {
      if (this.loading) return;
      if (!kennisNode) {
        this.router.navigate([], { queryParams: { kennisNode: null } }).then();
      } else {
        this.router.navigate([], { queryParams: { kennisNode: kennisNode.dataInstanceUid } }).then();
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
    this.currentKennisNodeSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.kennisNodesSubscription?.unsubscribe();
  }

  async loadModule() {
    return this.loadingScreenService.show(async () => {
      if (!this.moduleUid) throw new Error('Failed to load module, no module uid provided');

      this.module = await this.dataService.loadModule(this.moduleUid);
      this.moduleId = this.module.fieldValues.find((fv) => fv.field === 'moduleId')?.value as string;
      this.moduleName = this.module.fieldValues.find((fv) => fv.field === 'displayName')?.value as string;
    });
  }

  async onAddKennisNode(title: string) {
    if (!title) throw new Error('No title provided');

    return this.loadingScreenService.show(async () => {
      // Create new node
      const newNode = await this.dataService.initStruct('KennisNode');

      // Set title
      this.dataService.updateFieldValue(newNode.uid, 'title', title).then();

      // Add to kennisboom
      if (!this.module) return;

      const currentKennisboom = await this.dataService.getDataInstance(
        this.module.fieldValues.find((fieldValue) => fieldValue.field === 'kennisboom')?.value as string,
      );
      if (!currentKennisboom) return;

      const kennisNodes = currentKennisboom.fieldValues.find((fieldValue) => fieldValue.field === 'kennisNodes');
      if (!kennisNodes || !Array.isArray(kennisNodes?.value)) {
        throw new Error('Could not find kennisNodes in kennisboom');
      }

      if (kennisNodes) {
        if (kennisNodes.value) kennisNodes.value.push(newNode.uid);
        else kennisNodes.value = [newNode.uid];
      }

      // Save kennisboom
      this.dataService.updateDataInstance(currentKennisboom).then();

      // Update the nodesUpdated list
      await this.dataService.nodeAdded(newNode, title);
    });
  }

  openOverview() {
    this.dataService.currentNodeChanged.next(undefined);
  }

  async deleteKennisNode(kennisNodeDataInstanceUid: string) {
    if (!this.module || !this.currentKennisNode) return;

    // Delete the node from dataService
    const nodeInstance = await this.dataService.getDataInstance(kennisNodeDataInstanceUid);
    if (!nodeInstance) return;
    await this.dataService.deleteNode(nodeInstance.uid);
    this.dataService.deleteDataInstance(nodeInstance).then();

    // Delete the node from the kennisboom
    const currentKennisboom = await this.dataService.getDataInstance(
      this.module.fieldValues.find((fieldValue) => fieldValue.field === 'kennisboom')?.value as string,
    );
    if (!currentKennisboom) return;

    const kennisNodes = currentKennisboom.fieldValues.find((fieldValue) => fieldValue.field === 'kennisNodes');
    if (!kennisNodes || !kennisNodes.value || !Array.isArray(kennisNodes.value)) {
      console.error('No kennisNodes found in kennisboom');
      return;
    }

    kennisNodes.value = kennisNodes.value.filter((uid: string) => uid !== kennisNodeDataInstanceUid);
    this.dataService.updateDataInstance(currentKennisboom).then();

    // Delete the node from the outgoingConnections field of other nodes
    for (const uid of kennisNodes.value as string[]) {
      const node = await this.dataService.getDataInstance(uid);
      if (!node) continue;

      const outgoingConnections = node.fieldValues.find((fieldValue) => fieldValue.field === 'outgoingConnections');
      if (!outgoingConnections || !outgoingConnections.value || !Array.isArray(outgoingConnections.value)) {
        console.error('No outgoingConnections found in node');
        continue;
      }

      outgoingConnections.value = outgoingConnections.value.filter((connection: string) => connection !== kennisNodeDataInstanceUid);

      this.dataService.updateDataInstance(node).then();
    }

    // Let the data service send the nodeDeleted event
    await this.dataService.nodeDeleted(kennisNodeDataInstanceUid);

    // Set the current node to undefined
    this.dataService.currentNodeChanged.next(undefined);
  }
}
