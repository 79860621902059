<div *ngIf="useForm" class="d-flex">
  <select #dropDown (change)="update()" class="form-select" id="dropdown-form" name="dropdown">
    <option *ngFor="let choice of choices" [value]="choice.value">{{ choice.name }}</option>
  </select>
  <button (click)="onViewStruct()" *ngIf="data && data.value.includes('di_')" class="btn btn-primary"><i
    class="bi-box-arrow-up-right"></i></button>
</div>


<div *ngIf="!useForm" class="d-flex">
  <select #dropDown (change)="update()" class="" id="dropdown" name="dropdown">
    <option *ngFor="let choice of choices" [value]="choice.value">{{ choice.name }}</option>
  </select>
  <button (click)="onViewStruct()" *ngIf="data && data.value.includes('di_')" class="btn btn-primary"><i
    class="bi-box-arrow-up-right"></i></button>
</div>
