<div class="card-body">
  <div class="row space">
    <div class="col-6">
      <h4 class="card-title">{{ "Background" | prettifyName }}</h4>
    </div>
    <div class="col-6">
      <h4 class="card-title">{{ "Character" | prettifyName }}</h4>
    </div>
  </div>
  <div class="row space">
    <div class="col-6">
      <img alt="Background" class="img-fluid" height="200" ngSrc="{{ backgroundUrl }}" width="350"/>
    </div>
    <div class="col-6">
      <img alt="character" class="img-fluid" height="200" ngSrc="{{ characterUrl }}" width="350"/>
    </div>
  </div>
  <div class="row space">
    <div class="col-6">
      <app-dropdown-field (dropdownUpdated)="changeBackground($event)"
                          *ngIf="backgroundFieldData.dataInstanceUid"
                          [choices]="backgrounds"
                          [data]="backgroundFieldData"
      />
    </div>
    <div class="col-6">
      <app-dropdown-field (dropdownUpdated)="changeCharacter($event)"
                          *ngIf="characterFieldData.dataInstanceUid"
                          [choices]="characters"
                          [data]="characterFieldData"
      />
    </div>
  </div>
</div>
