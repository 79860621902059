import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FieldData } from '../../../models/data/FieldData';
import { DataInstance } from '../../../models/data/DataInstance';
import { DataService } from '../../../_services/data-management/data.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DynamicFieldComponent } from '../../dynamic-fields/dynamic-field.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-inline-list-editor',
  templateUrl: './inline-list-editor.component.html',
  styleUrls: ['./inline-list-editor.component.scss'],
})
export class InlineListEditorComponent implements OnInit, DynamicFieldComponent<FieldData<string[]> | undefined> {
  @Input() data: FieldData<string[]> | undefined;

  instances: DataInstance[] = [];
  structTypes: string[] = [];
  typeName: string | undefined;

  constructor(
    private dataService: DataService,
    private modalService: NgbModal,
  ) {}

  async ngOnInit() {
    if (!this.data) return;

    // Get the instances from the data service
    for (const instanceUid of this.data.value) {
      const instance = await this.dataService.getDataInstance(instanceUid);
      if (!instance) throw new Error('Data instance not found');
      this.instances.push(instance);
    }

    const dataInstance = await this.dataService.getDataInstance(this.data.dataInstanceUid);
    if (!dataInstance) throw new Error('Data instance not found');

    const field = this.dataService.getField(this.data.fieldId, dataInstance.dataType);

    // If the type of the field is a list of enums, we need to get the struct types from the data service
    if (field.type.startsWith('List<Enum')) {
      const enumType = this.dataService.getEnumType(this.dataService.getTypeIdFromRefType(field.type));
      this.typeName = enumType.name;
      this.structTypes = enumType.options.sort();
    } else if (field.type.startsWith('List<Struct')) {
      this.typeName = this.dataService.getTypeIdFromRefType(field.type);
      this.structTypes = [this.typeName];
    } else throw new Error(`Field type "${field.type}" is not a list of enums or structs`);
  }

  openModal(content: TemplateRef<NgbModalRef>) {
    this.modalService.dismissAll('Closed before opening new modal');
    this.modalService.open(content, { ariaLabelledBy: 'upload-modal-title' }).result.then();
  }

  async addInstance(structTypeId: string) {
    if (!this.data) return;

    this.instances.push(await this.dataService.initStruct(structTypeId));

    // Update the data instance
    if (!this.data.value || !Array.isArray(this.data.value)) this.data.value = [];

    this.data.value.push(this.instances[this.instances.length - 1].uid);
    await this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value);
  }

  async deleteInstance(instanceToDelete: DataInstance) {
    if (!this.data) return;

    // Update the data instance
    this.data.value = this.data.value.filter((instanceUid: string) => instanceUid !== instanceToDelete.uid);
    await this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value);

    await this.dataService.deleteDataInstance(instanceToDelete);
    this.instances = this.instances?.filter((listInstance: DataInstance) => listInstance.uid !== instanceToDelete.uid) ?? [];
  }

  onStructTypeSelected(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedStructType = selectElement.value;
    if (selectedStructType) {
      this.addInstance(selectedStructType);
      selectElement.value = '';
    }
  }

  async onMoveDataInstanceOrder(event: CdkDragDrop<DataInstance[]>) {
    if (!this.data || !this.data.value) return;
    moveItemInArray(this.data?.value, event.previousIndex, event.currentIndex);
    await this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value);
    moveItemInArray(this.instances, event.previousIndex, event.currentIndex);
  }
}
