import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataService } from '../../../../_services/data-management/data.service';
import { Resource } from '../../../../models/data/Resource';
import fieldData, { FieldData } from '../../../../models/data/FieldData';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { DynamicFieldComponent } from '../../dynamic-field.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dropdown-field',
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss'],
})
export class DropdownFieldComponent implements OnInit, AfterViewInit, DynamicFieldComponent<FieldData<string | string[]> | undefined> {
  @ViewChild('dropDown') dropdown!: ElementRef<HTMLSelectElement>;

  // TODO: delete index this is only necessary for fields that are list of references to other resources.
  //  For this another component is created. Namely, the ResourceSelectorComponent.
  @Input({
    transform: (value: FieldData<string | string[] | unknown>) => fieldData.transform(value),
  })
  data: FieldData<string | string[]> | undefined;
  @Input() index = 0;
  @Input() choices: Resource[] = [];

  @Output() dropdownUpdated = new EventEmitter<string>();

  useForm = true;

  constructor(
    private dataService: DataService,
    private alertService: AlertService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.data && this.data.fieldId === 'target_activity') this.useForm = false;
  }

  ngAfterViewInit() {
    if (!this.data) throw new Error('Data not found');
    if (Array.isArray(this.data.value)) {
      if (this.data.value.length > this.index) this.dropdown.nativeElement.value = this.data.value[this.index];
      else {
        this.data.value.push(this.choices[0].value);
        this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value).then();
      }
    } else {
      // If the value is already set, set the dropdown to that value. Otherwise, set it to the first value
      if (this.data.value) this.dropdown.nativeElement.value = this.data.value;
      else this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.dropdown.nativeElement.value).then();
    }
  }

  update() {
    if (!this.data) throw new Error('Data not found');
    if (Array.isArray(this.data.value)) this.data.value[this.index] = this.dropdown.nativeElement.value;
    else this.data.value = this.dropdown.nativeElement.value;
    this.dropdownUpdated.emit(this.dropdown.nativeElement.value);
    this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value).then();
    this.showAlert();
  }

  showAlert() {
    if (!this.data) return;
    this.alertService.showAlert('Updating ' + this.data.name + '...', BootstrapClass.INFO);
  }

  onViewStruct() {
    if (!this.data) return;

    const resource = this.dataService.getResourceStructs().find((resource) => this.data?.fieldType.includes(resource));
    if (!resource) {
      if (this.data.fieldType.includes('Activity')) {
        this.router.navigate([], { queryParams: { activity: this.data.value } }).then();
      }
    }
    if (!resource || !this.data.value.includes('di_')) return;
    this.router.navigate([`/home/${resource}/${this.data.value}`]).then();
  }
}
