<p class="mb-1">Type of {{ enumType }}</p>
<select (change)="update()" *ngIf="data" [(ngModel)]="currentChoice" class="form-select"
        name="dropdown">
  <option *ngFor="let choice of choices" [value]="choice.value">{{ choice.name | prettifyName }}</option>
</select>
<app-variable-comparison *ngIf="data && currentChoiceDataInstance && currentChoice === 'VariableComparison'"
                         [instance]="currentChoiceDataInstance"></app-variable-comparison>
<app-set-variable-field *ngIf="data && currentChoiceDataInstance && currentChoice === 'SetVariableAction'"
                        [instance]="currentChoiceDataInstance"></app-set-variable-field>
<app-struct-instance-editor
  *ngIf="data && currentChoiceDataInstance && currentChoice !== 'VariableComparison' && currentChoice !== 'SetVariableAction'"
  [data]="data"></app-struct-instance-editor>
