import { Component, Input, OnInit } from '@angular/core';
import { FieldData } from '../../../../models/data/FieldData';
import { Resource } from '../../../../models/data/Resource';
import { DataService } from '../../../../_services/data-management/data.service';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { DynamicFieldComponent } from '../../dynamic-field.component';

@Component({
  selector: 'app-select-type-selector',
  templateUrl: './select-type-selector.component.html',
  styleUrls: ['./select-type-selector.component.scss'],
})
export class SelectTypeSelectorComponent implements OnInit, DynamicFieldComponent<FieldData<string> | undefined> {
  @Input() data: FieldData<string> | undefined;

  choices: Resource[] = [];

  constructor(
    private dataService: DataService,
    private alertService: AlertService,
  ) {}

  async ngOnInit() {
    if (!this.data) {
      console.error('Data or select type not found');
      return;
    }
    const instance = await this.dataService.getDataInstance(this.data.dataInstanceUid);
    if (!instance) throw new Error('Data instance not found');

    // In order to get the select type, we need to get the field first, which needs the StructTypeId
    const structTypeId = instance.dataType;
    //Get the select type from the data field
    const field = this.dataService.getField(this.data.fieldId, structTypeId);

    const selectTypeId = this.dataService.getTypeIdFromRefType(field.type);
    // Get the select type options
    this.choices = this.dataService.getSelectTypeResource(selectTypeId).slice();

    // If the data value is not set, set it to the default value if any
    if (field.defaultValue) {
      this.data.value = this.data.value || this.choices[this.choices.findIndex((choice) => choice.name === field.defaultValue)].value;
    } else {
      this.data.value = this.data.value || this.choices[0].value;
    }
  }

  // Update the data instance when the dropdown is changed
  update() {
    // Note that this.data.value is already updated by the dropdown, which use [(ngModel)].
    if (!this.data) throw new Error('Data not found');
    this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value).then();
    this.showAlert();
  }

  showAlert() {
    if (!this.data) return;
    this.alertService.showAlert('Updating ' + this.data.name + '...', BootstrapClass.INFO);
  }
}
