import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, filter, firstValueFrom, lastValueFrom, map, Observable, Subject } from 'rxjs';
import { EnumType } from '../../models/schema/EnumType';
import { StructType } from '../../models/schema/StructType';
import { DataInstance } from '../../models/data/DataInstance';
import { HTTPRequestService } from './HTTP-request.service';
import { Field } from '../../models/schema/Field';
import { FieldValue } from '../../models/data/FieldValue';
import { FlowchartNode } from '../../models/data/FlowchartNode';
import { Resource } from '../../models/data/Resource';
import { FileMeta } from '../../models/data/FileMeta';
import { AuthService, AuthState } from '../authorization/auth.service';
import { SelectTypeOption } from '../../models/schema/SelectTypeOption';
import { SelectType } from '../../models/schema/SelectType';
import { ConvertService } from '../convert-service';
import { environment } from '../../../environments/environment';
import { NodeCategory } from '../../models/types/NodeCategory';
import { AlertService } from '../UI-elements/alert-service';
import { BootstrapClass } from '../../models/types/BootstrapClass';
import { deepCopy, generateRandomString } from '../utils';
import { Vector2 } from '../../models/types/Vector2';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Variable } from '../../models/schema/Variable';
import { VariableType } from '../../models/schema/VariableType';
import { NodePosition } from '../../models/schema/NodePosition';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  currentMissionUid = '';

  readonly nodesUpdated = new BehaviorSubject<FlowchartNode[]>([]);
  readonly nodesUpdated$ = this.nodesUpdated.asObservable();

  readonly currentNodeChanged = new BehaviorSubject<FlowchartNode | undefined>(undefined);
  readonly currentNodeChanged$ = this.currentNodeChanged.asObservable();

  readonly currentActivityChanged = new BehaviorSubject<FlowchartNode | undefined>(undefined);
  readonly currentActivityChanged$ = this.currentActivityChanged.asObservable();

  activities: FlowchartNode[] = [];
  activitiesUpdated = new BehaviorSubject<FlowchartNode[]>(this.activities);
  activitiesUpdated$ = this.activitiesUpdated.asObservable();
  activityTypeIDs: Record<string, number> = {};

  readonly currentGameId = environment.defaultGame;
  readonly currentDataPackage: string = environment.dataPackage;

  private updatePositions = new EventEmitter();
  readonly updatePositions$ = this.updatePositions.asObservable();

  private instanceUpdated = new BehaviorSubject<DataInstance | undefined>(undefined);
  readonly instanceUpdated$ = this.instanceUpdated.asObservable();

  private fileUploaded = new Subject<FileMeta>();
  readonly fileUploaded$ = this.fileUploaded.asObservable();

  // For now there is no possibility to choose the game nor the datapackage.
  private fieldTypes: Field[] = [];
  private structTypes: StructType[] = [];
  private enumTypes: EnumType[] = [];
  private selectTypes: SelectType[] = [];
  private variables: Variable[] = [];
  private resources: { [index: string]: Resource[] } = {};
  private selectTypeResources: { [index: string]: Resource[] } = {};
  private enumTypeResources: { [index: string]: Resource[] } = {};
  private resourceStructs: string[] = [];

  private dataServiceInitialized = new BehaviorSubject<boolean>(false);

  private knownDataInstances: Map<string, DataInstance> = new Map();
  private dataInstanceRequests: Map<string, Observable<DataInstance>> = new Map();

  constructor(
    private requestService: HTTPRequestService,
    private authService: AuthService,
    private convertService: ConvertService,
    private alertService: AlertService,
  ) {
    this.authService.state.subscribe((state) => {
      if (state === AuthState.LOGGED_IN) {
        this.init().then();
      }
    });
  }

  async waitForInit() {
    return firstValueFrom(this.dataServiceInitialized.pipe(filter(Boolean)));
  }

  async init() {
    const data = await firstValueFrom(this.requestService.getSchema(environment.defaultGame));
    this.structTypes = data.structTypes;
    this.enumTypes = data.enumTypes;
    this.selectTypes = data.selectTypes;
    this.variables = data.variables;

    for (const structType of data.structTypes) {
      for (const field of structType.fields) {
        if (!this.fieldTypes.includes(field)) {
          this.fieldTypes.push(field);
        }
      }
    }

    await this.loadResources();
    await this.loadSelectTypes();
    await this.loadEnumTypes();

    this.dataServiceInitialized.next(true);
  }

  async loadResources() {
    const structTypes = await lastValueFrom(this.requestService.getStructTypes(this.currentGameId));

    for (const structType of structTypes) {
      const typeIdIndex = this.resourceStructs.indexOf(structType.typeId);
      // Add Struct to resourceStructs if it is a resource and is not already present
      if (structType.isResource) {
        if (typeIdIndex === -1) {
          this.resourceStructs.push(structType.typeId);
        }
      } else {
        // Remove Struct from resource if it is present in resourceStructs but isn't a resource
        if (typeIdIndex !== -1) {
          this.resourceStructs.splice(typeIdIndex, 1);
        }
      }
    }

    this.resourceStructs.sort();

    if (!this.resourceStructs.includes('Variable')) this.resourceStructs.push('Variable');

    for (const structType of structTypes) {
      if (!this.resourceStructs.includes(structType.typeId)) {
        continue;
      }

      this.resources[structType.typeId] = [];

      const resourceInstances = await this.getDataInstancesPerStructType(structType.typeId, undefined, undefined, true);

      for (const resource of resourceInstances) {
        // TODO: We need to get a better way of knowing which field is the name field, on top of that some structs
        //  do not have a representative name field. That's why for now we check if the name is undefined and if so
        //  we take the first field as the name field. This is not a good solution.
        const resourceName =
          (resource.fieldValues.find((fieldValue) => fieldValue.field === 'name' || fieldValue.field === 'displayName')?.value as
            | string
            | undefined) ?? (resource.fieldValues[0].value as string);

        this.resources[structType.typeId].push({
          name: resourceName,
          value: resource.uid,
        });
      }
    }

    const variables = await lastValueFrom(this.requestService.getVariables(this.currentGameId, this.currentDataPackage));
    this.resources['Variable'] = [];
    for (const variable of variables) {
      this.resources['Variable'].push({
        name: variable.name,
        value: variable.variableRef,
      });
    }
  }

  async loadSelectTypes() {
    // Get all SelectTypes from the database and add the options to the selectTypes object
    const selectTypes = await lastValueFrom(this.requestService.getSelectTypes(this.currentGameId));

    for (const selectType of selectTypes) {
      // Convert the select type options to resources
      this.selectTypeResources[selectType.typeId] = selectType.options.map((option: SelectTypeOption) => {
        return this.convertService.convertSelectTypeOption(option);
      });
    }
  }

  async loadEnumTypes() {
    // Get all enumTypes from the database and add the options to the enumTypes object
    const enumTypes = await lastValueFrom(this.requestService.getEnumTypes(this.currentGameId));

    for (const enumType of enumTypes) {
      // Convert the select type options to resources
      this.enumTypeResources[enumType.typeId] = enumType.options.map((option) => {
        return this.convertService.convertEnumTypeOption(option);
      });
    }
  }

  getResource(structType: string) {
    if (this.resources[structType]) return this.resources[structType];
    return [];
  }

  getSelectTypeResource(selectType: string) {
    return this.selectTypeResources[selectType];
  }

  getEnumTypeResource(enumType: string) {
    return this.enumTypeResources[enumType];
  }

  getResourceStructs() {
    return this.resourceStructs;
  }

  getCurrentDataInstances(): DataInstance[] {
    return [...this.knownDataInstances.values()];
  }

  async getMissionInfos() {
    return lastValueFrom(this.requestService.getDataInstancesOfStruct(this.currentGameId, this.currentDataPackage, 'MissionInfo'));
  }

  async getDataInstance(dataInstanceUid: string, fresh = false): Promise<DataInstance> {
    if (!dataInstanceUid) {
      throw new Error('Data instance uid is not set');
    }

    if (fresh || !this.knownDataInstances.has(dataInstanceUid)) {
      if (this.dataInstanceRequests.has(dataInstanceUid)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return lastValueFrom(this.dataInstanceRequests.get(dataInstanceUid)!);
      }

      const instanceRequest = fromPromise(this.getDataInstanceFromDB(undefined, undefined, 'shouldnotmatter', dataInstanceUid));
      this.dataInstanceRequests.set(dataInstanceUid, instanceRequest);

      const instance = await lastValueFrom(instanceRequest);
      this.dataInstanceRequests.delete(dataInstanceUid);

      return instance;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.knownDataInstances.get(dataInstanceUid)!;
  }

  async getDataInstancesPerStructType(
    structTypeId: string,
    gameId: string = this.currentGameId,
    dataPackage: string = this.currentDataPackage,
    force = false,
  ): Promise<DataInstance[]> {
    if (!force) await this.waitForInit();

    const dataInstances = await lastValueFrom(this.requestService.getDataInstancesOfStruct(gameId, dataPackage, structTypeId));

    for (const dataInstance of dataInstances) {
      for (const fieldValue of dataInstance.fieldValues) {
        if (!fieldValue.value) continue;

        if (typeof fieldValue.value === 'string')
          fieldValue.value = this.convertService.parse(fieldValue.value, this.getField(fieldValue.field, dataInstance.dataType));
        else console.warn('Field value is not a string: ' + fieldValue.value);
      }
    }

    return dataInstances;
  }

  async getDataInstanceFromDB(
    gameId: string | undefined = this.currentGameId,
    dataPackage: string | undefined = this.currentDataPackage,
    structTypeId: string,
    dataInstanceUid: string,
  ): Promise<DataInstance> {
    await this.waitForInit();

    const dataInstance = await lastValueFrom(
      this.requestService.getDataInstance(
        gameId ?? this.currentGameId,
        dataPackage ?? this.currentDataPackage,
        structTypeId,
        dataInstanceUid,
      ),
    );

    // "Cache" all the data instances in the knownDataInstances map
    for (const instance of [dataInstance, ...Object.values(dataInstance.subObjects ?? [])]) {
      const copied = deepCopy(instance);

      if (copied.subObjects) {
        for (const [key, dataInstance] of Object.entries(copied.subObjects)) {
          this.knownDataInstances.set(key, dataInstance);
        }
      }

      delete copied.subObjects;
      copied.fieldValuesMap = {};

      for (const fieldValueIndex in copied.fieldValues) {
        const fieldValue = copied.fieldValues[fieldValueIndex];

        if (typeof fieldValue.value !== 'string') {
          // I don't know if this is a bad thing...
          console.warn('Field value is not a string: ', fieldValue.value);
          continue;
        }

        const parsed = this.convertService.parse(fieldValue.value, this.getField(fieldValue.field, copied.dataType));

        const value: FieldValue<unknown> = {
          ...fieldValue,
          value: parsed,
        };

        copied.fieldValuesMap[fieldValue.field] = value;
        copied.fieldValues[fieldValueIndex] = value;
      }

      this.knownDataInstances.set(instance.uid, copied);
    }

    if (!this.knownDataInstances.has(dataInstanceUid)) {
      throw new Error('Data instance: ' + dataInstanceUid + ' not found');
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.knownDataInstances.get(dataInstanceUid)!;
  }

  //Returns the field values of the data instance with the given uid
  async getFieldValuesOfDataInstance(dataInstanceUid: string | unknown, fetchFromDatabase = true) {
    const dataInstance =
      this.knownDataInstances.get(dataInstanceUid as string) ??
      (fetchFromDatabase && (await this.getDataInstance(dataInstanceUid as string)));
    if (!dataInstance) throw new Error('Data instance: ' + dataInstanceUid + ' not found');
    return dataInstance?.fieldValues;
  }

  getStructType(typeId: string): StructType {
    const structType = this.structTypes.find((structType) => structType.typeId === typeId);
    if (!structType) throw new Error('Struct type: ' + typeId + ' not found');
    return structType;
  }

  getSelectType(typeId: string): SelectType {
    // todo: find out why triple equals operator check does not work here
    const selectType = this.selectTypes.find((selectType) => selectType.typeId == typeId);
    if (!selectType) throw new Error('SelectType: ' + typeId + ' not found');
    return selectType;
  }

  getEnumType(typeId: string): EnumType {
    const enumType = this.enumTypes.find((enumType) => enumType.typeId === typeId);
    if (!enumType) throw new Error('Enum type: ' + typeId + ' not found');
    return enumType;
  }

  async getVariable(variableRef: string): Promise<Variable> {
    await this.waitForInit();
    const variable = this.variables.find((v) => v.variableRef === variableRef);
    if (!variable) throw new Error('Variable: ' + variableRef + ' not found');
    return variable;
  }

  async getAllVariables(): Promise<Variable[]> {
    await this.waitForInit();
    return this.variables;
  }

  isEnumType(typeId: string): boolean {
    return this.enumTypes.some((enumType) => enumType.typeId === typeId);
  }

  isStructType(typeId: string): boolean {
    return this.structTypes.some((structType) => structType.typeId === typeId);
  }

  getField(fieldId: string, structTypeId: string): Field {
    const structType = this.structTypes.find((structType) => structType.typeId === structTypeId);
    if (!structType) throw new Error('Struct type: ' + structTypeId + ' not found');

    const field = structType.fields.find((field) => field.fieldId === fieldId);
    if (!field) throw new Error("Field '" + fieldId + "' not found for struct type: " + structTypeId);

    return field;
  }

  // Get file meta of all files in datapackage
  async getFilesMeta(dataPackageUid: string): Promise<FileMeta[]> {
    return lastValueFrom(this.requestService.getFilesMetaOfDataPackage(this.currentGameId, dataPackageUid).pipe(map((data) => data.files)));
  }

  // Adds a new data instance to the data service and to the database
  async createDataInstance(dataInstance: DataInstance): Promise<DataInstance> {
    await this.waitForInit();

    // If any of the field values has a value that is not a string, we need to stringify it
    for (const fieldValue of dataInstance.fieldValues) {
      fieldValue.value = this.convertService.stringify(fieldValue.value, this.getField(fieldValue.field, dataInstance.dataType));
    }

    const newDataInstance = await lastValueFrom(
      this.requestService.postDataInstance(this.currentGameId, this.currentDataPackage, dataInstance.dataType, dataInstance),
    );
    this.buildFieldValueMap(newDataInstance);

    // If the data instance is a resource update the resources list
    if (this.resourceStructs.includes(dataInstance.dataType)) {
      const resourceName = dataInstance.fieldValues.find((fieldValue) => {
        return fieldValue.field === 'name' || fieldValue.field === 'displayName';
      })?.value as string;

      // If this is the first instance of this resource, initialize an empty array.
      if (!this.resources[dataInstance.dataType]) this.resources[dataInstance.dataType] = [];
      this.resources[dataInstance.dataType].push({
        name: resourceName,
        value: newDataInstance.uid,
      });
    }

    // Add the data instance to the data service and resolve the promise
    this.knownDataInstances.set(newDataInstance.uid, newDataInstance);
    return newDataInstance;
  }

  async createVariable(variable: Variable): Promise<Variable> {
    await this.waitForInit();

    const newVariable = await lastValueFrom(this.requestService.postSchemaVariable(this.currentGameId, this.currentDataPackage, variable));
    this.variables.push(newVariable);
    return newVariable;
  }

  async uploadFile(formData: FormData): Promise<FileMeta> {
    const meta = await lastValueFrom(this.requestService.uploadFile(this.currentGameId, this.currentDataPackage, formData));
    this.fileUploaded.next(meta);

    return meta;
  }

  async replaceFile(fileUid: string, formData: FormData): Promise<FileMeta> {
    const uploadResponse = await lastValueFrom(
      this.requestService.replaceFile(this.currentGameId, this.currentDataPackage, fileUid, formData),
    );
    if (!uploadResponse) throw new Error("Didn't receive FileMeta after upload?");
    this.fileUploaded.next(uploadResponse);

    return uploadResponse;
  }

  async updateFileNameAndAlt(fileUid: string, newName: string, newAlt: string): Promise<FileMeta> {
    const fileMeta = await lastValueFrom(
      this.requestService.updateFileNameAndAlt(this.currentGameId, this.currentDataPackage, fileUid, {
        name: newName,
        alt: newAlt,
      }),
    );
    if (!fileMeta) throw new Error("Didn't receive FileMeta after upload?");
    return fileMeta;
  }

  async initStruct(structTypeId: string, createOptionalFields = false): Promise<DataInstance> {
    await this.waitForInit();

    const fieldValues = await this.createFieldValues(structTypeId, createOptionalFields);
    const createdInstance = await this.createDataInstance({
      uid: 'Should not matter',
      dataType: structTypeId,
      fieldValues: fieldValues,
      fieldValuesMap: {},
    });

    console.log('Created ' + structTypeId, createdInstance);

    if (!createdInstance) throw new Error('Could not create instance');
    return createdInstance;
  }

  async initVariable() {
    await this.waitForInit();

    const createdVariable = await this.createVariable({
      variableRef: 'Should not matter',
      name: '',
      description: '',
      // A variable without a type is just going to be a pain
      valueType: VariableType.String,
      startValue: '',
      type: 'Variable',
    });

    console.log('Created variable', createdVariable);
    if (!createdVariable) throw new Error('Could not create variable');
    return createdVariable;
  }

  async updateFieldValue(dataInstanceUid: string, fieldId: string, value: unknown) {
    const dataInstanceToUpdate = await this.getDataInstance(dataInstanceUid);

    if (!dataInstanceToUpdate) {
      console.warn('No data instance found with uid: ' + dataInstanceUid);
      return;
    }

    const fieldValueToUpdate = dataInstanceToUpdate.fieldValues.find((fieldValue) => fieldValue.field === fieldId);

    if (fieldValueToUpdate) {
      fieldValueToUpdate.value = value;
    } else {
      const dataType = this.getStructType(dataInstanceToUpdate.dataType);
      if (dataType.fields.findIndex((field) => field.fieldId === fieldId) === -1) {
        console.warn(`Skipping value update: Field "${fieldId}" not found in struct type: ${dataType.typeId}`);
        return;
      }

      // create a new field value if a value is given
      const fieldValue = this.createFieldValue(fieldId, value);
      dataInstanceToUpdate.fieldValues.push(fieldValue);
      dataInstanceToUpdate.fieldValuesMap ??= {};
      dataInstanceToUpdate.fieldValuesMap[fieldId] = fieldValue;
    }

    await this.updateDataInstance(dataInstanceToUpdate);
  }

  // Updates the data instance, with the given uid, in the data service and in the database
  async updateDataInstance(dataInstance: DataInstance): Promise<DataInstance> {
    // TODO: dataInstance.fieldValuesMap should be merged with dataInstance.fieldValues

    await this.waitForInit();

    // Copy the data instance, so that the original data instance is not changed
    const dataInstanceToUpdate = deepCopy(dataInstance);

    // If any of the field values has a value that is not a string, we need to stringify it
    for (const fieldValue of dataInstanceToUpdate.fieldValues) {
      fieldValue.value = this.convertService.stringify(fieldValue.value, this.getField(fieldValue.field, dataInstance.dataType));
    }

    // If the data instance is a resource update the resources list
    if (this.resourceStructs.includes(dataInstance.dataType)) {
      const resource = this.resources[dataInstance.dataType].find((resource) => resource.value === dataInstance.uid);
      if (resource) {
        resource.name = dataInstance.fieldValues.find((fieldValue) => {
          return fieldValue.field === 'name' || fieldValue.field === 'displayName';
        })?.value as string;
      }
    }

    const data = await lastValueFrom(
      this.requestService.updateDataInstance(this.currentGameId, this.currentDataPackage, dataInstance.dataType, dataInstanceToUpdate),
    );
    if (!data) throw new Error('Data instance not found: ' + dataInstance.uid);
    this.buildFieldValueMap(data);

    this.instanceUpdated.next(data);
    return data;
  }

  async updateVariable(variable: Variable) {
    await this.waitForInit();

    const updatedVariable = await lastValueFrom(this.requestService.updateVariable(this.currentGameId, this.currentDataPackage, variable));
    if (!updatedVariable) throw new Error('Variable not found: ' + variable.variableRef);
    this.variables = this.variables.map((v) => (v.variableRef === variable.variableRef ? updatedVariable : v));
  }

  async deleteDataInstance(
    dataInstance: DataInstance,
    options?: {
      force?: boolean;
      throwError?: boolean;
    },
  ) {
    await this.waitForInit();

    this.alertService.showAlert('Deleting ' + dataInstance.dataType + '...', BootstrapClass.DANGER);

    try {
      const params: Record<string, string> = {};
      if (options?.force) params['force'] = 'true';

      await lastValueFrom(
        this.requestService.deleteDataInstance(
          this.currentGameId,
          this.currentDataPackage,
          dataInstance.dataType,
          dataInstance.uid,
          params,
        ),
      );

      // Remove the data instance from the activities list (if it is an activity) and from the data instances list
      this.activities = this.activities.filter((activity) => activity.dataInstanceUid !== dataInstance.uid);
      this.knownDataInstances.delete(dataInstance.uid);

      // If the data instance is a resource, remove it from the resources list
      if (this.resources && this.resources[dataInstance.dataType]) {
        this.resources[dataInstance.dataType] = this.resources[dataInstance.dataType].filter(
          (resource) => resource.value !== dataInstance.uid,
        );
      }
    } catch (e) {
      console.error(`Error deleting data instance "${dataInstance.uid}": `, e);
      if (options?.throwError) throw e;
    }
  }

  async deleteDataInstances(dataInstanceUids: string[]) {
    await this.waitForInit();
    this.alertService.showAlert('Deleting data instances...', BootstrapClass.DANGER);
    try {
      await lastValueFrom(this.requestService.deleteDataInstances(this.currentGameId, this.currentDataPackage, dataInstanceUids));

      // Remove the data instances from the activities list and from the data instances list
      this.activities = this.activities.filter((activity) => !dataInstanceUids.includes(activity.dataInstanceUid));
      dataInstanceUids.forEach((uid) => this.knownDataInstances.delete(uid));
    } catch (e) {
      console.error(`Error deleting data instances "${dataInstanceUids}": `, e);
      throw e;
    }
  }

  async deleteVariable(variable: Variable, options?: { throwError?: boolean }) {
    await this.waitForInit();
    this.alertService.showAlert('Deleting ' + variable.name + '...', BootstrapClass.DANGER);
    try {
      await lastValueFrom(this.requestService.deleteVariable(this.currentGameId, variable.variableRef));

      this.variables = this.variables.filter((v) => v.variableRef !== variable.variableRef);
    } catch (e) {
      console.error(`Error deleting variable "${variable.variableRef}": `, e);
      if (options?.throwError) throw e;
    }
  }

  async downloadMission(missionInfoUid: string) {
    await this.waitForInit();
    const dataInstance = await lastValueFrom(
      this.requestService.getDataInstance(this.currentGameId, this.currentDataPackage, 'MissionInfo', missionInfoUid),
    );
    const instancesJSON = JSON.stringify(dataInstance, (key, value) => value);
    const data = 'text/json;charset=utf-8,' + encodeURIComponent(instancesJSON);
    const a = document.createElement('a');
    a.href = 'data:' + data;
    a.download = missionInfoUid + '.json';
    a.click();
  }

  async duplicateDataInstance(dataInstanceUid: string) {
    await this.waitForInit();
    return await lastValueFrom(this.requestService.duplicateDataInstance(this.currentGameId, this.currentDataPackage, dataInstanceUid));
  }

  async deleteFile(fileUid: string) {
    await this.waitForInit();
    return await lastValueFrom(this.requestService.deleteFile(this.currentGameId, this.currentDataPackage, fileUid));
  }

  saveButtonCalled() {
    this.updatePositions.emit();
  }

  async nodeAdded(newNodeDataInstance: DataInstance, title: string) {
    /* TODO: Find a better moment to update nodes position.
     *  The problem is that when you update the nodes position, when the position is saved, the editor needs to be
     *  reloaded. This is not a good user experience. So we need to find a way to update the nodes position without
     *  reloading the editor.
     */
    const nodes = await this.updateNodesPosition();
    nodes.push({
      dataInstanceUid: newNodeDataInstance.uid,
      name: title,
      type: 'Normal',
      nodeCategory: NodeCategory.Kennis,
      fieldValues: newNodeDataInstance.fieldValues,
    });
    this.nodesUpdated.next(nodes);
  }

  async nodeDeleted(dataInstanceUid: string) {
    let nodes = await this.updateNodesPosition();
    nodes = nodes.filter((n: FlowchartNode) => n.dataInstanceUid !== dataInstanceUid);
    this.nodesUpdated.next(nodes);
  }

  editNode(node: FlowchartNode) {
    // If the node is the same as the current node, do nothing
    if (this.currentNodeChanged.value?.dataInstanceUid === node.dataInstanceUid) return;
    this.currentNodeChanged.next(node);
  }

  // Loads module and its kennisboom by module uid and returns the module
  async loadModule(moduleUid: string) {
    await this.waitForInit();

    // Reset data instances
    this.knownDataInstances = new Map<string, DataInstance>();

    // Get the information of the module
    const moduleDataInstance = await this.getDataInstanceFromDB(this.currentGameId, this.currentDataPackage, 'Module', moduleUid);

    // Load the referenced data instances of the module
    for (const fieldValue of moduleDataInstance.fieldValues) {
      await this.loadDataInstances(fieldValue, 'Module');
    }

    const instance = await this.getDataInstance(moduleUid);
    if (!instance) throw new Error('Could not load module with uid: ' + moduleUid);

    const kennisboomInstance = (await this.getDataInstance(moduleUid))?.fieldValues.find((fieldValue) => fieldValue.field === 'kennisboom')
      ?.value as string | undefined;
    if (!kennisboomInstance) throw new Error('Could not load kennisboom with uid: ' + kennisboomInstance);

    const nodeUids = (await this.getDataInstance(kennisboomInstance))?.fieldValues.find((fieldValue) => fieldValue.field === 'kennisNodes')
      ?.value as string[] | undefined;
    if (!nodeUids) throw new Error('Could not load nodes with uid: ' + nodeUids);

    // For each uid, get the node and add it to the nodes list as a flowchart node
    const nodes: FlowchartNode[] = [];
    for (const nodeUid of nodeUids) {
      const nodeDataInstance = await this.getDataInstance(nodeUid);
      if (!nodeDataInstance) throw new Error('Could not load node with uid: ' + nodeUid);

      // Note: there is a node position system in CAS, but we don't want to use it for kennisnodes, because there the
      // position is stored in the actual datamodel

      const position = nodeDataInstance.fieldValues.find((fieldValue) => fieldValue.field === 'position')?.value as Vector2;
      // We need to flip the y-axis because the saved value is used for a different coordinate system
      position.y = -position.y;

      nodes.push({
        dataInstanceUid: nodeDataInstance.uid,
        name: nodeDataInstance.fieldValues.find((fieldValue) => fieldValue.field === 'title')?.value as string,
        fieldValues: nodeDataInstance.fieldValues,
        nodeCategory: NodeCategory.Kennis,
        type: nodeDataInstance.fieldValues.find((fieldValue) => fieldValue.field === 'kennisNodeType')?.value as string,
        position: position,
      });
    }
    // Set the nodes as the current nodes
    this.nodesUpdated.next(nodes);

    return moduleDataInstance;
  }

  // Loads mission by mission info uid and returns the mission info
  async loadMission(missionInfoUid: string): Promise<DataInstance> {
    await this.waitForInit();

    //Reset activities
    this.activities = [];
    this.activityTypeIDs = {};
    this.knownDataInstances = new Map<string, DataInstance>();

    // Get the information of the mission
    const missionInfoDataInstance = await this.getDataInstanceFromDB(
      this.currentGameId,
      this.currentDataPackage,
      'MissionInfo',
      missionInfoUid,
    );
    const fields = this.getStructType('MissionInfo').fields;

    // Add missing fields
    for (const field of fields) {
      if (!missionInfoDataInstance.fieldValues.find((f) => f.field === field.fieldId)) {
        missionInfoDataInstance.fieldValues.push({ field: field.fieldId, value: '' });
      }

      missionInfoDataInstance.fieldValuesMap ??= {};
      if (!(field.fieldId in missionInfoDataInstance.fieldValuesMap)) {
        missionInfoDataInstance.fieldValuesMap[field.fieldId] = { field: field.fieldId, value: '' };
      }
    }

    for (const fieldValue of missionInfoDataInstance.fieldValues) {
      if (fieldValue.field === 'mission') {
        if (typeof fieldValue.value === 'string') this.currentMissionUid = fieldValue.value;
        else console.error('Invalid mission uid: ', fieldValue.value);
      }
      // await this.loadDataInstances(fieldValue, 'MissionInfo');
    }

    const currentMission = await this.getDataInstance(this.currentMissionUid);
    if (!currentMission)
      throw new Error('Could not load mission with uid: ' + this.currentMissionUid + ' for missionInfo with uid: ' + missionInfoUid);

    const getUids = (data: string | string[]): string[] => {
      if (Array.isArray(data)) return data;
      try {
        return JSON.parse(data as string);
      } catch (e) {
        return (data as string).split(',');
      }
    };

    // Get the activity Uids of the mission
    const activityUids: string[] = currentMission.fieldValues
      .filter((fieldValue) => fieldValue.field === 'activities')
      .flatMap((f) => getUids(f.value as string | string[]));

    // For each uid, get the activity and add it to the activities list
    for (const activityUid of Object.values(activityUids)) {
      const activityDataInstance = await this.getDataInstance(activityUid);

      if (!activityDataInstance) {
        console.warn('Could not load activity with uid: ' + activityUid);
        continue;
      }

      const nameField = activityDataInstance.fieldValues.find((fieldValue) => fieldValue.field === 'name');
      let name = '';
      if (!nameField) {
        name = activityDataInstance.dataType + '_' + generateRandomString(10);
        await this.updateFieldValue(activityDataInstance.uid, 'name', name);
      } else {
        name = nameField.value as string;
      }

      if (!this.activityTypeIDs[activityDataInstance.dataType]) this.activityTypeIDs[activityDataInstance.dataType] = 1;

      let nodePosition: NodePosition;
      try {
        nodePosition = await lastValueFrom(this.requestService.getNodePosition(this.currentGameId, activityUid));
      } catch (e) {
        console.warn('Could not load node position for activity with uid: ' + activityUid + ', falling back to 0;0');
        nodePosition = { dataInstanceUid: activityUid, positionX: 0, positionY: 0 };
      }
      const o: FlowchartNode = {
        dataInstanceUid: activityDataInstance.uid,
        name: name,
        type: activityDataInstance.dataType,
        fieldValues: activityDataInstance.fieldValues,
        nodeCategory: NodeCategory.Activity,
        position: {
          x: nodePosition.positionX,
          y: nodePosition.positionY,
        },
      };

      this.activities.push(o);
    }

    this.activitiesUpdated.next(this.activities);
    return missionInfoDataInstance;
  }

  // Loads all the referenced data instances of the given field value, recursively
  async loadDataInstances(fieldValue: FieldValue<unknown>, dataType: string) {
    const field = this.getField(fieldValue.field, dataType);

    // If the Field is a not Ref or a List<Ref>, we can continue
    // Else we need to get the referenced data instance(s) from the database
    if (!field.type.startsWith('Struct') && !field.type.startsWith('Enum') && !field.type.startsWith('List')) {
      return;
    }

    const typeId = this.getTypeIdFromRefType(field.type);

    // If it is a reference to a mission info or module, we do not need to load it.
    // Because then we would load another mission or module.
    if (typeId === 'MissionInfo' || typeId === 'Module') {
      return;
    }

    const dataInstanceUids: string[] =
      (field.type.startsWith('List') ? (fieldValue.value as string[]) : [fieldValue.value as string]) ?? [];

    // For each reference get the data instance from the database
    for (const dataInstanceUid of dataInstanceUids.filter(Boolean)) {
      // Add only when not already loaded
      if (this.knownDataInstances.has(dataInstanceUid)) continue;

      try {
        const dataInstance = await this.getDataInstance(dataInstanceUid, true);
        if (!dataInstance) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error('Could not load data instance with uid: ' + dataInstanceUid);
        }

        // Load the referenced data instances of the previously loaded data instance
        for (const fieldValue of dataInstance.fieldValues) {
          await this.loadDataInstances(fieldValue, dataInstance.dataType);
        }
      } catch (e: unknown) {
        const error = e as Error;

        if (!('status' in error) || error.status !== 404) {
          throw error;
        }

        if (field.type.startsWith('List')) {
          fieldValue.value = ((fieldValue.value as string[]) ?? []).filter((value) => value !== dataInstanceUid);
        } else {
          fieldValue.value = '';
        }
      }
    }
  }

  // Initializes the fieldvalues of a structtype. Note, this does not create the data instance or fieldvalues in the database
  async createFieldValues(structTypeId: string, createOptionalFields = false) {
    await this.waitForInit();
    const fields = this.getStructType(structTypeId).fields;
    const fieldValues: FieldValue<unknown>[] = [];

    for (const field of fields) {
      if (!createOptionalFields && !field.required) continue;
      let defaultValue = field.defaultValue;
      if (field.type.startsWith('Enum<PlaceableMedia>')) defaultValue = (await this.initStruct('ImagePlaceableMedia')).uid;
      else if (
        // TODO: Replace with general Null check
        field.type.startsWith('Enum<') ||
        field.type.startsWith('Struct<') ||
        field.type.startsWith('EnumRef<') ||
        field.type.startsWith('StructRef<') ||
        field.type.startsWith('ImageRef') ||
        field.type.startsWith('VideoRef') ||
        field.type.startsWith('AudioRef')
      ) {
        defaultValue = '';
      }
      fieldValues.push(this.createFieldValue(field.fieldId, defaultValue));
    }

    return fieldValues;
  }

  // Returns a field value with the given field id and value
  createFieldValue<T = unknown>(fieldId: string, value: T): FieldValue<T> {
    return { field: fieldId, value: value };
  }

  // Returns the typeId from the field type
  getTypeIdFromRefType(refType: string) {
    // TODO: Change endpoint such that you can get a datainstance without knowing the structtype
    // Reference to structtype look like: "List<StructRef<STRUCT>>" or "StructRef<STRUCT>"
    // And reference to enumtype look like: "List<EnumRef<ENUM>>" or "EnumRef<ENUM>"
    // Therefore struct name is between < and >.
    return refType.substring(refType.lastIndexOf('<') + 1, refType.indexOf('>'));
  }

  async updateNodesPosition() {
    const nodes = this.nodesUpdated.value;
    for (const node of nodes) {
      // Update each node's position
      const nodeDataInstance = await this.getDataInstance(node.dataInstanceUid);
      if (!nodeDataInstance) throw new Error('Node data instance not found');

      node.position = nodeDataInstance.fieldValues.find((f) => f.field === 'position')?.value as Vector2 | undefined;
    }
    return nodes;
  }

  async downloadFile(fileUid: string): Promise<Blob> {
    await this.waitForInit();
    return await lastValueFrom(this.requestService.downloadFile(this.currentGameId, this.currentDataPackage, fileUid));
  }

  async saveNodePosition(nodeUid: string, position: Vector2) {
    this.alertService.showAlert('Saving position of node...', BootstrapClass.INFO);
    return await lastValueFrom(this.requestService.updateNodePosition(this.currentGameId, nodeUid, position));
  }

  async deleteNode(nodeUid: string) {
    await lastValueFrom(this.requestService.deleteNodePosition(this.currentGameId, nodeUid));
  }

  async getModuleBelongingToMission(parentDatatype: string, currentDatatype: string, missionInfoUid: string) {
    return await lastValueFrom(
      this.requestService.getDataInstanceWithChildWithDatatypeAndFieldValue(
        this.currentGameId,
        this.currentDataPackage,
        parentDatatype,
        currentDatatype,
        missionInfoUid,
      ),
    );
  }

  private buildFieldValueMap(dataInstance: DataInstance) {
    dataInstance.fieldValuesMap ??= {};

    for (const fieldValue of dataInstance.fieldValues) {
      dataInstance.fieldValuesMap[fieldValue.field] = fieldValue;

      if (typeof fieldValue.value === 'string')
        fieldValue.value = this.convertService.parse(fieldValue.value, this.getField(fieldValue.field, dataInstance.dataType));
      else console.error('Field value is not a string', fieldValue);
    }
  }
}
