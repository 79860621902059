import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { HTTPRequestService } from '../data-management/HTTP-request.service';
import { DataService } from '../data-management/data.service';
import { SearchRequest } from '../../models/search/SearchRequest';
import { NavigationExtras } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private readonly shown = new BehaviorSubject<boolean>(false);
  public readonly shown$ = this.shown.asObservable();

  constructor(
    private requestService: HTTPRequestService,
    private dataService: DataService,
  ) {}

  public show() {
    this.shown.next(true);
  }

  public hide() {
    this.shown.next(false);
  }

  public toggle() {
    if (this.shown.value) this.hide();
    else this.show();
  }

  public search(query: SearchRequest) {
    return this.requestService.search(this.dataService.currentGameId, this.dataService.currentDataPackage, query);
  }

  /**
   * A method to find the url of a data instance, if it exists. Also handles special cases like MissionInfo and Modules.
   * @param uid
   */
  public async findDataInstanceUrl(uid: string): Promise<[string[], NavigationExtras?]> {
    const [dataInstance, { parent, traversedChildren }] = await Promise.all([
      this.dataService.getDataInstance(uid),
      lastValueFrom(this.requestService.getDataInstanceParent(this.dataService.currentGameId, this.dataService.currentDataPackage, uid)),
    ]);

    switch (parent.dataType) {
      case 'MissionInfo': {
        const activityEnum = this.dataService.getEnumType('Activity');
        const activity = activityEnum.options.includes(dataInstance.dataType)
          ? dataInstance.uid
          : traversedChildren.find((child) => activityEnum.options.includes(child.dataType))?.uid;

        return [
          [`/home/mission/${parent.uid}`],
          {
            queryParams: {
              activity: activity,
            },
          },
        ];
      }

      case 'Module': {
        return [
          [`/home/module/${parent.uid}`],
          {
            queryParams: {
              kennisNode: dataInstance.uid,
            },
          },
        ];
      }

      default: {
        return [[`/home/${parent.dataType}/${parent.uid}`]];
      }
    }
  }
}
