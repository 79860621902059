<div class="row">
  <div *ngFor="let message of messages">
    <!--  Messages use different CSS styling based on the character that sends the message-->
    <div
      [ngClass]="{
        'player-message': isSentByPlayer(message),
        'guide-message': isSentByGuide(message),
        'NPC-message': isSentByNPC(message)
      }"
      class="message"
    >
      <app-dropdown-field
        [choices]="chatCharacters"
        [data]="convert(message.fields['character'])"
        class="chat"
      />

      <ng-container *ngIf="message.structType === 'TextMessage'">
        <app-struct-instance-editor
          *ngIf="isStructOrRef(message.fields['text'].fieldType, 'TextWithAudio')"
          [data]="convert(message.fields['text'])"
          [editorType]="'SeamlessInline'"
          class="chat"
          resourceStructType="TextWithAudio"
        />

        <app-text-input-field
          *ngIf="message.fields['text'].fieldType === 'string'"
          [data]="convert(message.fields['text'])"
          class="chat"
        />
      </ng-container>

      <ng-container *ngIf="message.structType === 'ImageMessage'">
        <img alt="" height="100" ngSrc="{{ mediaUrls[message.uid] }}" width="150"/>

        <app-file-upload
          (newFileSelected)="changeImageOfMessage($event, message.uid)"
          [data]="convert(message.fields['image'])"
        />
      </ng-container>

      <app-struct-instance-editor
        *ngIf="message.structType === 'RunActionsMessage'"
        [data]="convert(message.fields['actions'])"
        class="chat"
        editorType="SeamlessInline"
      />

      <button (click)="moveMessageUp(message)" [disabled]="isFirstMessage(message)" class="arrow-btn">
        <img alt="buttonpng" height="20" ngSrc="../../../../../assets/images/arrow_up.png" width="20"/>
      </button>

      <button (click)="moveMessageDown(message)" [disabled]="isLastMessage(message)" class="arrow-btn">
        <img alt="buttonpng" height="20" ngSrc="../../../../../assets/images/arrow_down.png" width="20"/>
      </button>

      <button (click)="removeMessage(message)" class="btn btn-no-space btn-outline-danger">
        <span class="bi bi-trash"></span>
      </button>
    </div>
  </div>
</div>

<select #messageType class="form-select" name="dropdown">
  <option [value]="'TextMessage'">Text message</option>
  <option [value]="'ImageMessage'">Image message</option>
  <option [value]="'RunActionsMessage'">Run Action message</option>
</select>

<!--<button class="btn btn-success btn-space" (click)="addMessage('NPC', messageType.value)">NPC Message</button>-->
<!--<button class="btn btn-success btn-space" (click)="addMessage('GUIDE', messageType.value)">Guide Message</button>-->
<!--<button class="btn btn-success btn-space" (click)="addMessage('PLAYER', messageType.value)">Player Message</button>-->
<!--For now we always add a player message-->
<button (click)="addMessage('NPC', messageType.value)" class="btn btn-success btn-space">Add Message</button>
