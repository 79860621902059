import { Type } from '@angular/core';
import { FieldData } from '../../models/data/FieldData';

type ComponentType = Type<{ data?: FieldData<unknown>; editorType?: string }> & { editorType?: string };

export class DynamicFieldObject<T = unknown> {
  constructor(
    public component: ComponentType,
    public data: FieldData<T>,
  ) {}
}

export function Seamless(component: ComponentType): ComponentType {
  return class extends component {
    static override editorType = 'SeamlessInline';
  };
}

export function Hidden(component: ComponentType): ComponentType {
  return class extends component {
    static override editorType = 'Hidden';
  };
}
