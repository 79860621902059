<div class="container">
  <div class="resource-title">
    <h1 [ngbPopover]="structTypeDescription" class="text-center text-white py-3"
        triggers="hover">Variables</h1>
    <button (click)="createVariable()" class="btn btn-primary"><i class="bi bi-plus-circle"></i></button>
  </div>
  <table class="table table-striped table-hover">
    <thead>
    <tr>
      <th scope="col">Variable Name</th>
      <th scope="col">Variable Ref</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let variable of variables">
      <td (click)="openVariable(variable)" class="cursor-pointer">{{ variable.name }}</td>
      <td (click)="openVariable(variable)" class="cursor-pointer font-monospace">{{ variable.variableRef }}</td>
      <td>
        <button (click)="openVariable(variable)" class="btn btn-no-space btn-outline-dark align-right">
          <span class="bi bi-pencil-square"></span>
        </button>
        <button (click)="copyIdToClipboard(variable.variableRef)"
                class="btn btn-no-space btn-outline-dark align-right"
                ngbPopover="Copy ID" triggers="hover"
        >
          <span class="bi bi-code"></span>
        </button>
        <button (click)="deleteVariable(variable)" class="btn btn-no-space btn-outline-dark align-right">
          <span class="bi bi-trash"></span>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="text-center">
    <button (click)="createVariable()" class="btn btn-primary"><i class="bi bi-plus-circle"></i></button>
  </div>
</div>
