import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FlowchartNode } from '../../../../models/data/FlowchartNode';
import { DataService } from '../../../../_services/data-management/data.service';
import { Subscription } from 'rxjs';
import { DataInstance } from '../../../../models/data/DataInstance';
import { FieldData } from '../../../../models/data/FieldData';

@Component({
  selector: 'app-flowchart-node',
  templateUrl: './flowchart-node.component.html',
  styleUrls: ['./flowchart-node.component.scss'],
})
export class FlowchartNodeComponent implements OnInit, OnDestroy {
  @Input() node?: FlowchartNode;
  @Output() deleteNode: EventEmitter<string> = new EventEmitter<string>();
  nodeInstance?: DataInstance;
  kennisNodeFieldData?: FieldData<string>;
  private currentNodeSubscription?: Subscription;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.currentNodeSubscription = this.dataService.currentActivityChanged$.subscribe((node) => {
      this.update(node).then();
    });
  }

  deleteKennisNode() {
    if (!this.node) return;
    this.deleteNode.emit(this.node.dataInstanceUid);
  }

  ngOnDestroy() {
    this.currentNodeSubscription?.unsubscribe();
  }

  private async update(newNode: FlowchartNode | undefined) {
    this.node = newNode;

    if (!this.node) return;
    this.nodeInstance = await this.dataService.getDataInstanceFromDB(undefined, undefined, 'KennisNode', this.node.dataInstanceUid);
    this.kennisNodeFieldData = {
      dataInstanceUid: this.nodeInstance.uid,
      fieldId: '',
      fieldType: '',
      name: '',
      description: '',
      value: this.nodeInstance.uid,
    };
  }
}
