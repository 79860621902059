import { Injectable } from '@angular/core';
import { DynamicFieldObject, Hidden, Seamless } from '../../_components/dynamic-fields/dynamic-field.object';
import { Field } from '../../models/schema/Field';
import { FieldValue } from '../../models/data/FieldValue';
import { ChatFieldComponent } from '../../_components/dynamic-fields/non-primitive-fields/chat-field/chat-field.component';
import { DataService } from './data.service';
import { FieldData } from '../../models/data/FieldData';
import { IconFieldComponent } from '../../_components/dynamic-fields/primitive-fields/icon-field/icon-field.component';
import { InlineListEditorComponent } from '../../_components/editors/inline-list-editor/inline-list-editor.component';
import { StructInstanceEditorComponent } from '../../_components/editors/struct-instance-editor/struct-instance-editor.component';
import { TextAreaFieldComponent } from '../../_components/dynamic-fields/primitive-fields/text-area-field/text-area-field.component';
import { AudioFieldComponent } from '../../_components/dynamic-fields/non-primitive-fields/audio-field/audio-field.component';
import { CheckFieldComponent } from '../../_components/dynamic-fields/primitive-fields/check-field/check-field.component';
import { NumberInputFieldComponent } from '../../_components/dynamic-fields/primitive-fields/number-input-field/number-input-field.component';
import { ImageFieldComponent } from '../../_components/dynamic-fields/non-primitive-fields/image-field/image-field.component';
import { SelectTypeSelectorComponent } from '../../_components/dynamic-fields/primitive-fields/select-type-selector/select-type-selector.component';
import { HTTPRequestService } from './HTTP-request.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ResourceSelectorFieldComponent } from '../../_components/dynamic-fields/non-primitive-fields/resource-selector-field/resource-selector-field.component';
import { EnumInstanceEditorComponent } from '../../_components/editors/enum-instance-editor/enum-instance-editor.component';
import { VideoFieldComponent } from '../../_components/dynamic-fields/non-primitive-fields/video-field/video-field.component';
import { ColorFieldComponent } from '../../_components/dynamic-fields/primitive-fields/color-field/color-field-component';
import { CanvasEditorComponent } from '../../_components/editors/canvas-editor/canvas-editor.component';
import { ChatComponent } from '../../_components/editors/mission/activity/chat/chat.component';
import { VectorFieldComponent } from '../../_components/dynamic-fields/primitive-fields/vector-field/vector-field.component';

@Injectable({
  providedIn: 'root',
})
export class DynamicFieldService {
  dynamicFieldServiceInitialized = new BehaviorSubject<boolean>(false);
  private editorTypesPerField: Record<string, string> = {};

  constructor(
    private dataService: DataService,
    private httpRequestService: HTTPRequestService,
  ) {
    this.init().then();
  }

  async init() {
    const editor = await lastValueFrom(this.httpRequestService.getFieldEditors(this.dataService.currentGameId));
    this.editorTypesPerField = editor.reduce<Record<string, string>>((acc, { fieldId, structId, editorType }) => {
      const key = `${structId}.${fieldId}`; // Concatenate structType and fieldId to form the key
      if (editorType) {
        acc[key] = editorType;
      }
      return acc;
    }, {});
    this.dynamicFieldServiceInitialized.next(true);
  }

  getDynamicFieldObjects(
    fieldValues: FieldValue<unknown>[],
    dataInstanceUid: string,
    structTypeId: string,
    getUninitialized = false,
  ): Record<string, DynamicFieldObject> {
    // We create a list of Dynamic field objects for each field value
    const dynamicFieldObjects: { [index: string]: DynamicFieldObject } = {};
    const structType = this.dataService.getStructType(structTypeId);

    // For each field in the struct type, we create a dynamic field object
    for (const field of structType.fields) {
      const fieldValue = fieldValues.find((fv) => fv.field === field.fieldId);
      if (fieldValue) {
        const component = this.getComponent(field, structTypeId);
        if (component)
          dynamicFieldObjects[field.fieldId] = new DynamicFieldObject(component, this.getData(field, dataInstanceUid, fieldValue.value));
        // If getUninitialized is true, we create a dynamic field object for each field that does not have a field value
      } else if (getUninitialized) {
        const component = this.getComponent(field, structTypeId);
        if (component) dynamicFieldObjects[field.fieldId] = new DynamicFieldObject(component, this.getData(field, dataInstanceUid, ''));
      }
    }

    return dynamicFieldObjects as Record<string, DynamicFieldObject>;
  }

  getComponent(field: Field, structTypeId: string): DynamicFieldObject['component'] | undefined {
    // if (field.type === 'StructRef<TextWithAudio>' || field.type === 'Struct<TextWithAudio>') {
    //   return TextWithAudioComponent;
    // }

    const editorType = this.editorTypesPerField[structTypeId + '.' + field.fieldId];
    switch (editorType) {
      case 'CanvasEditor':
        return Seamless(CanvasEditorComponent);
      case 'ChatEditor':
        return Seamless(ChatComponent);
      case 'Chat':
        return ChatFieldComponent;
      case 'Hidden':
        return Hidden(this.getComponentForType(field.type));
      case 'Inline': {
        if (field.type.startsWith('StructRef<')) {
          return ResourceSelectorFieldComponent;
        }
        if (field.type.startsWith('Struct<')) {
          return StructInstanceEditorComponent;
        }
        if (field.type.startsWith('EnumRef<')) {
          return ResourceSelectorFieldComponent;
        }
        if (field.type.startsWith('Enum<')) {
          return EnumInstanceEditorComponent;
        }

        if (!field.type.includes('<') && !field.type.includes('>')) {
          // Always inlined anyway
          return this.getComponentForType(field.type);
        }

        return InlineListEditorComponent;
      }
      case 'SeamlessInline': {
        if (field.type.startsWith('StructRef<')) {
          return Seamless(ResourceSelectorFieldComponent);
        }
        if (field.type.startsWith('Struct<')) {
          return Seamless(StructInstanceEditorComponent);
        }
        if (field.type.startsWith('EnumRef<')) {
          return Seamless(ResourceSelectorFieldComponent);
        }
        if (field.type.startsWith('Enum<')) {
          return Seamless(EnumInstanceEditorComponent);
        }
        return Seamless(InlineListEditorComponent);
      }
      default:
        return this.getComponentForType(field.type);
    }
  }

  getData<T = unknown>(field: Field, dataInstanceUid: string, value: T): FieldData<T> {
    return {
      dataInstanceUid: dataInstanceUid,
      fieldId: field.fieldId,
      fieldType: field.type,
      name: field.name,
      fieldEditor: field.fieldEditor,
      description: field.description,
      value: value,
    };
  }

  private getComponentForType(fieldType: string): DynamicFieldObject['component'] {
    switch (fieldType) {
      case 'string':
        return TextAreaFieldComponent;
      case 'bool':
      case 'boolean':
        return CheckFieldComponent;
      case 'int':
      case 'float':
        return NumberInputFieldComponent;
      case 'ImageRef':
        return ImageFieldComponent;
      case 'VideoRef':
        return VideoFieldComponent;
      case 'AudioRef':
        return AudioFieldComponent;
      case 'Icon':
        return IconFieldComponent;
      case 'Color':
        return ColorFieldComponent;
      default: {
        if (fieldType.startsWith('Vector')) return VectorFieldComponent;
        if (
          fieldType.startsWith('List<StructRef<') ||
          fieldType.startsWith('List<EnumRef<') ||
          fieldType.startsWith('StructRef<') ||
          fieldType.startsWith('EnumRef<')
        )
          return ResourceSelectorFieldComponent;
        if (fieldType.startsWith('List<Struct<') || fieldType.startsWith('List<Enum<')) return InlineListEditorComponent;
        if (fieldType.startsWith('Struct<')) return StructInstanceEditorComponent;
        if (fieldType.startsWith('Enum<')) return EnumInstanceEditorComponent;
        if (fieldType.startsWith('Select<')) return SelectTypeSelectorComponent;
        throw new Error('Unknown field type: ' + fieldType);
      }
    }
  }
}
