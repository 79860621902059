import { Component } from '@angular/core';
import { DynamicFieldService } from './_services/data-management/dynamic-field.service';
import { SubcomponentService } from './_services/data-management/subcomponent.service';
import { Title } from '@angular/platform-browser';
import { LoadingScreenService } from './_services/UI-elements/loading-screen.service';
import { delay } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DynamicFieldService, SubcomponentService],
})
export class AppComponent {
  showSpinner = false;

  constructor(
    private loadingScreenService: LoadingScreenService,
    private titleService: Title,
  ) {
    this.titleService.setTitle(`CAS`);
    this.loadingScreenService.shown$
      .pipe(
        // We use delay(0) to prevent ExpressionChangedAfterItHasBeenCheckedError
        delay(0),
      )
      .subscribe((showSpinner: boolean) => (this.showSpinner = showSpinner));
  }
}
