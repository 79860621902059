import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-duplication-complete-modal',
  standalone: true,
  imports: [],
  templateUrl: './duplication-complete-modal.component.html',
})
export class DuplicationCompleteModalComponent {
  @Input({ required: true }) modal!: NgbModalRef;
  @Input({ required: true }) info!: { name: string };

  @Output() openInstance = new EventEmitter<void>();

  clickOpenInstance() {
    this.openInstance.emit();
    this.close();
  }

  close() {
    this.modal.dismiss('Cross click');
  }
}
