import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FieldData } from '../../../../models/data/FieldData';
import { Field } from '../../../../models/schema/Field';
import { DataService } from '../../../../_services/data-management/data.service';
import { Resource } from '../../../../models/data/Resource';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { DynamicFieldComponent } from '../../dynamic-field.component';
import { instant } from '../../../../_services/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-selector-field',
  templateUrl: './selector-field.component.html',
  styleUrls: ['./selector-field.component.scss'],
})
export class SelectorFieldComponent implements OnInit, OnChanges, DynamicFieldComponent<FieldData<string | string[]> | undefined> {
  @Input({
    transform: (value: FieldData<string | string[] | unknown> | undefined) =>
      !value ? undefined : (value as FieldData<string | string[]>),
  })
  data: FieldData<string | string[]> | undefined;

  @Input() choices: Resource[] = [];
  @Input() loading = false;
  @Output() reload = new EventEmitter<void>();

  selectedFieldData: FieldData<string[]> | undefined;
  field?: Field;
  isList = false;

  protected readonly Array = Array;

  constructor(
    protected dataService: DataService,
    private alertService: AlertService,
    private router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('data' in changes) {
      this.initData(changes['data'].currentValue);
    }

    if ('choices' in changes) {
      this.updateChoices();
    }
  }

  async ngOnInit() {
    if (!this.data) return;

    // If the field is a list, set the isList flag to true
    const dataInstance = await this.dataService.getDataInstance(this.data.dataInstanceUid);
    if (!dataInstance) throw new Error('Data instance not found');

    this.field = this.dataService.getField(this.data.fieldId, dataInstance.dataType);
    this.isList = this.field.type.startsWith('List');

    this.initData(this.data);

    // Add an empty choice to the front of the list, if it is not a list
    this.updateChoices();
  }

  // Add and remove are only used for lists
  addResourceSelector() {
    if (!this.selectedFieldData || !this.data) throw new Error('Data not found');

    // If there are no choices, show warning alert
    if (this.choices.length < 1) {
      this.alertService.showAlert('No ' + this.data.name + ' found', BootstrapClass.WARNING);
      return;
    }

    // Add the value to the array, which also initializes a new dropdown
    if (this.isList) this.selectedFieldData.value.push(this.choices[0].value);
    else this.selectedFieldData.value = [this.choices[0].value];

    this.saveUpdates().then();
  }

  removeResourceSelector(indexToRemove: number) {
    if (!this.data || !this.selectedFieldData) throw new Error('Data not found');

    // Remove the value from the array
    if (this.isList) this.selectedFieldData.value.splice(indexToRemove, 1);
    else this.selectedFieldData.value = [];

    this.saveUpdates().then();
  }

  // Update the data instance when the dropdown is changed
  saveUpdates() {
    return instant(() => {
      if (!this.selectedFieldData) throw new Error('Data not found');
      this.alertService.showAlert('Updating ' + this.selectedFieldData.name + '...', BootstrapClass.INFO);
      return this.dataService.updateFieldValue(
        this.selectedFieldData.dataInstanceUid,
        this.selectedFieldData.fieldId,
        this.isList ? this.selectedFieldData.value : this.selectedFieldData.value[0],
      );
    });
  }

  onViewStruct(index: number) {
    if (!this.data) return;

    const currentFieldType = this.data?.fieldType.substring(this.data?.fieldType.lastIndexOf('<') + 1, this.data?.fieldType.indexOf('>'));
    const isResource = this.dataService.getResourceStructs().includes(currentFieldType);
    if (!isResource) {
      if (currentFieldType.includes('Activity')) {
        this.router.navigate([], { queryParams: { activity: this.data.value[index] } }).then();
      }
    }

    if (currentFieldType === 'MissionInfo') {
      this.router.navigate(['/home/mission/' + this.data.value[index]]).then();
      return;
    }

    if (!isResource || !this.data.value[0].includes('di_')) return;
    this.router.navigate([`/home/${currentFieldType}/${this.data.value[index]}`]).then();
  }

  reloadChoices() {
    this.reload.emit();
  }

  private updateChoices() {
    if (!this.isList && this.field && !this.choices.find((s) => s.value === '')) {
      const typeId = this.dataService.getTypeIdFromRefType(this.field.type);
      this.choices = [{ name: 'Select ' + typeId, value: '' }, ...this.choices];
    }
  }

  private initData(data: FieldData<string | string[]>) {
    this.selectedFieldData = this.convertData(data);

    if (!this.selectedFieldData.value) {
      this.selectedFieldData.value = [];
    }
  }

  private convertData(data: FieldData<string | string[]>) {
    if (!Array.isArray(data.value)) data.value = [data.value];
    return data as FieldData<string[]>;
  }
}
