<div class="container">
  <!--    create a table for each module in modules. With columns module.uid, module.name, module.description-->
  <div class="resource-title">
    <h1 [ngbPopover]="structTypeDescription" class="text-center text-white py-3"
        triggers="hover">Modules</h1>
    <button (click)="createModule()" class="btn btn-primary"><i class="bi bi-plus-circle"></i></button>
  </div>
  <table class="table table-striped table-hover">
    <thead>
    <tr>
      <th scope="col">Module Name</th>
      <th scope="col">Module ID</th>
      <th scope="col">Module Uid</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let module of modules">
      <td (click)="openModule(module.moduleUid)" class="cursor-pointer">{{ module.name }} <span
        class="{{ module.icon }}"></span></td>
      <td (click)="openModule(module.moduleUid)" class="cursor-pointer">{{ module.moduleId }}</td>
      <td (click)="openModule(module.moduleUid)" class="cursor-pointer font-monospace">{{ module.moduleUid }}</td>
      <td>
        <a
          *ngIf="environment.playGameUrl"
          class="btn btn-no-space btn-outline-dark align-right"
          href="{{ environment.playGameUrl }}?offline=1&module={{ module.moduleId }}"
          target="_blank"
        >
          <span class="bi bi-play"></span>
        </a>
        <button (click)="openModule(module.moduleUid)" class="btn btn-no-space btn-outline-dark align-right">
          <span class="bi bi-pencil-square"></span>
        </button>
        <button (click)="copyResourceIdToClipboard(module.moduleUid)"
                class="btn btn-no-space btn-outline-dark align-right"
                ngbPopover="Copy UID. Not Module ID, but UID!" triggers="hover"
        >
          <span class="bi bi-code"></span>
        </button>
        <button (click)="deleteModule(module.moduleUid)" class="btn btn-no-space btn-outline-dark align-right">
          <span class="bi bi-trash"></span>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="text-center">
    <button (click)="createModule()" class="btn btn-primary"><i class="bi bi-plus-circle"></i></button>
  </div>
</div>
