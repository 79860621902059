import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Resource } from '../../../models/data/Resource';
import { DataService } from '../../../_services/data-management/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { ConfirmationModalService } from '../../../_services/UI-elements/confirmation-modal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { LoadingScreenService } from '../../../_services/UI-elements/loading-screen.service';
import { AlertService } from '../../../_services/UI-elements/alert-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
})
export class ResourceListComponent implements OnInit, OnDestroy {
  @ViewChild('duplicationCompleteModal') duplicationCompleteModal!: NgbModalRef;

  resources: Resource[] = [];
  resourceStructType = '';
  resourceStructTypeDescription = '';
  routeSub?: Subscription;

  protected newResource?: { name: string; value: string; uid: string };

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private confirmService: ConfirmationModalService,
    private router: Router,
    private loadingScreenService: LoadingScreenService,
    private alertService: AlertService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    return this.loadingScreenService.show(async () => {
      // Wait for the data service to initialize, otherwise the resources will not be loaded
      await this.dataService.waitForInit();

      this.routeSub = this.route.params.subscribe((params) => {
        this.initResources(params['resource']);
      });
    });
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
  }

  resourceName(resourceUid: string) {
    return this.resources?.find((resource) => resource.value === resourceUid)?.name;
  }

  async openResource(resourceUid: string) {
    // When opening resources, navigate to /:resource/:resourceUid
    await this.router.navigate(['/home', this.resourceStructType, resourceUid]);
  }

  createResource() {
    return this.loadingScreenService.show(async () => {
      const newResourceUid = (await this.dataService.initStruct(this.resourceStructType)).uid;
      await this.openResource(newResourceUid);
    });
  }

  /*
   * Delete a resource
   * @param resourceUid The resource's UID
   * @param force If set, the resource will be instantly deleted even if it is used in other places. References will be cleared
   */
  deleteResource(resourceUid: string, force = false): Promise<void> {
    return this.loadingScreenService.show(async () => {
      if (!force) {
        const resourceName = this.resourceName(resourceUid) || resourceUid;

        // Open a confirmation modal
        const confirmed = await firstValueFrom(
          this.confirmService.confirm('Are you sure you want to delete resource: "' + resourceName + '"?'),
        );

        if (!confirmed) return;
      }

      try {
        const resourceToDelete = await this.dataService.getDataInstanceFromDB(undefined, undefined, this.resourceStructType, resourceUid);

        await this.dataService.deleteDataInstance(resourceToDelete, {
          throwError: true,
          force,
        });

        this.resources.splice(
          this.resources.findIndex((r) => r.value === resourceUid),
          1,
        );
      } catch (e) {
        if (
          !force &&
          e instanceof HttpErrorResponse &&
          e.status === 409 &&
          confirm(
            'This resource is used in other places. Are you sure you want to delete it? This is a destructive action and cannot be undone.',
          )
        ) {
          return this.deleteResource(resourceUid, true);
        }

        console.error('Error deleting resource: ', e);
      }
    }) as Promise<void>;
  }

  duplicateResource(resourceUid: string) {
    return this.loadingScreenService.show(async () => {
      try {
        const dataInstance = await this.dataService.duplicateDataInstance(resourceUid);

        this.newResource = {
          name: (dataInstance.fieldValues.find((fv) => fv.field === 'name')?.value as string) + ' (Copy)',
          value: dataInstance.uid,
          uid: dataInstance.uid,
        };
        const index = this.resources.findIndex((r) => r.value === resourceUid);
        this.resources.splice(index, 0, this.newResource);

        this.modalService.dismissAll('Closed before opening new modal');
        this.modalService.open(this.duplicationCompleteModal, {
          ariaLabelledBy: 'duplication-success-modal',
          centered: true,
        });
      } catch (e) {
        this.alertService.showAlert('Failed to duplicate mission!', BootstrapClass.DANGER);
        throw e;
      }
    });
  }

  copyResourceIdToClipboard(resourceUid: string) {
    navigator.clipboard
      .writeText(resourceUid)
      .then(() => {
        this.alertService.showAlert('Resource ID copied to clipboard: ' + resourceUid, BootstrapClass.SUCCESS);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.alertService.showAlert('Failed to copy Resource ID!', BootstrapClass.DANGER);
      });
  }

  private initResources(resourceStructType: string) {
    this.resourceStructType = resourceStructType;
    this.resourceStructTypeDescription = this.dataService.getStructType(resourceStructType)?.description;

    this.resources = this.dataService.getResource(this.resourceStructType) ?? [];
    this.resources.sort((a, b) => {
      if (!a.name) return 1;
      if (!b.name) return -1;
      return a.name.localeCompare(b.name);
    });
  }
}
