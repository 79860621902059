import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Resource } from '../../../../../models/data/Resource';
import { DataService } from '../../../../../_services/data-management/data.service';
import { Subscription } from 'rxjs';
import { FieldData } from '../../../../../models/data/FieldData';
import { ActivatedRoute } from '@angular/router';
import { DataInstance } from '../../../../../models/data/DataInstance';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  // Just so that the field Directive will not throw errors of not having a data input
  @Input() data: FieldData<string> | undefined;

  activityInstance: DataInstance | undefined;

  backgroundUrl = 'assets/images/select_image.png';
  characterUrl = 'assets/images/select_image.png';
  characters: Resource[] = [{ value: '', name: 'Select a character' }];
  backgrounds: Resource[] = [{ value: '', name: 'Select a background' }];

  backgroundFieldData: FieldData<string> = {
    dataInstanceUid: '',
    fieldId: 'background',
    name: 'Background',
    value: '',
    fieldType: 'StructRef<Background>',
    description: 'The background of the conversation',
  };

  characterFieldData: FieldData<string> = {
    dataInstanceUid: '',
    fieldId: 'character',
    name: 'Character',
    value: '',
    fieldType: 'StructRef<Character>',
    description: 'The character the player chats with',
  };

  private routeSub?: Subscription;

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
  ) {}

  async ngOnInit() {
    this.routeSub = this.activatedRoute.queryParams.subscribe(async (params) => {
      const activityInstanceUid = params['activity'];
      if (!activityInstanceUid) {
        console.warn('No activity instance found');
        return;
      }
      await this.loadEditor(activityInstanceUid);
    });

    const characters = this.dataService.getResource('Character');
    if (characters) this.characters = [...this.characters, ...characters];

    const backgrounds = this.dataService.getResource('Background');
    if (backgrounds) this.backgrounds = [...this.backgrounds, ...backgrounds];
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
  }

  async changeCharacter(characterInstanceUid: string) {
    if (!characterInstanceUid) {
      this.characterUrl = 'assets/images/select_image.png';
      this.characterFieldData.value = '';
      return;
    }

    const characterInstance = await this.dataService.getDataInstance(characterInstanceUid);
    if (!characterInstance) throw new Error('Character not found');

    this.characterFieldData.value = characterInstanceUid;

    const imageMetaUid = characterInstance?.fieldValues.find((fv) => fv.field === 'normalSprite')?.value;
    if (!imageMetaUid) {
      console.warn('No image found for character');
      this.characterUrl = 'assets/images/select_image.png';
      return;
    }
    this.characterUrl = URL.createObjectURL(await this.dataService.downloadFile(imageMetaUid as string));
  }

  async changeBackground(backgroundInstanceUid: string) {
    if (!backgroundInstanceUid) {
      this.backgroundUrl = 'assets/images/select_image.png';
      this.backgroundFieldData.value = '';
      return;
    }

    const backgroundInstance = await this.dataService.getDataInstance(backgroundInstanceUid);
    if (!backgroundInstance) throw new Error('Background not found');

    this.backgroundFieldData.value = backgroundInstanceUid;

    const imageMetaUid = backgroundInstance.fieldValues.find((fv) => fv.field === 'image')?.value;
    if (!imageMetaUid) {
      console.error('No image found for character');
      this.backgroundUrl = 'assets/images/select_image.png';
      return;
    }

    this.backgroundUrl = URL.createObjectURL(await this.dataService.downloadFile(imageMetaUid as string));
  }

  private async loadEditor(activityInstanceUid: string) {
    this.activityInstance = await this.dataService.getDataInstance(activityInstanceUid);
    if (!this.activityInstance) {
      console.warn('Activity instance not found');
      return;
    }

    const backgroundField = this.activityInstance.fieldValues.find((fv) => fv.field === 'background');
    if (backgroundField && backgroundField.value) {
      await this.changeBackground(backgroundField.value as string);
    }
    this.backgroundFieldData.dataInstanceUid = activityInstanceUid;

    const characterField = this.activityInstance.fieldValues.find((fv) => fv.field === 'character');
    if (characterField && characterField.value) {
      await this.changeCharacter(characterField.value as string);
    }
    this.characterFieldData.dataInstanceUid = activityInstanceUid;
  }
}
