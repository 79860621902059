<input (colorPickerSelect)="update($event)"
       *ngIf="data"
       [colorPicker]="data.value"
       [cpAlphaChannel]="'always'"
       [cpOKButtonText]="'Save'"
       [cpOKButton]="true"
       [cpOutputFormat]="'hex'"
       [style.background]="data.value"
       [value]="data.value"
       class="form-control"
       style="width: 7rem" />
