import { Component, Input } from '@angular/core';
import { FieldData } from '../../../../models/data/FieldData';
import { DynamicFieldComponent } from '../../dynamic-field.component';

@Component({
  selector: 'app-image-field',
  templateUrl: './image-field.component.html',
  styleUrls: ['./image-field.component.scss'],
})
export class ImageFieldComponent implements DynamicFieldComponent<FieldData<string> | undefined> {
  @Input() data: FieldData<string> | undefined;

  currentURL = '/assets/images/select_image.png';
  currentName?: string;
  currentMimeType?: string = 'image/png';

  changeImage(imageUrl: { name: string; url: string; mimeType: string }) {
    this.currentURL = imageUrl.url;
    this.currentName = imageUrl.name;
    this.currentMimeType = imageUrl.mimeType;
  }
}
