import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  @Input() message = '';

  private resultSubject = new Subject<boolean>();
  public result = this.resultSubject.asObservable();

  constructor(public activeModal: NgbActiveModal) {}

  confirm() {
    this.resultSubject.next(true);
    this.activeModal.close(true);
  }
}
