export interface EnumType {
  typeId: string;
  name: string;
  description: string | undefined;
  options: string[];
  type: 'EnumType';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEnumType(obj: any): obj is EnumType {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.typeId === 'string' &&
    typeof obj.name === 'string' &&
    (typeof obj.description === 'string' || obj.description === undefined) &&
    Array.isArray(obj.options) &&
    obj.options.every((option: unknown) => typeof option === 'string') &&
    obj.type === 'EnumType'
  );
}
