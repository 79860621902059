import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataInstance } from '../../../models/data/DataInstance';
import { DynamicFieldObject } from '../../dynamic-fields/dynamic-field.object';
import { DataService } from '../../../_services/data-management/data.service';
import { DynamicFieldService } from '../../../_services/data-management/dynamic-field.service';
import { ActivatedRoute } from '@angular/router';
import { DynamicFieldComponent } from '../../dynamic-fields/dynamic-field.component';
import { Field } from '../../../models/schema/Field';
import { environment } from '../../../../environments/environment';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import fieldData, { FieldData } from '../../../models/data/FieldData';
import { LoadingScreenService } from '../../../_services/UI-elements/loading-screen.service';

@Component({
  selector: 'app-struct-instance-editor',
  templateUrl: './struct-instance-editor.component.html',
  styleUrls: ['./struct-instance-editor.component.scss'],
})
export class StructInstanceEditorComponent
  implements OnInit, OnDestroy, OnChanges, DynamicFieldComponent<FieldData<string | string[]> | undefined>
{
  @Input({
    transform: (value: FieldData<string | string[] | unknown>) => fieldData.transform(value),
  })
  data: FieldData<string> | undefined;

  @Input() resourceStructType?: string;
  @Input() currentResourceUid?: string;
  @Input() titleOverride?: string;
  @Input() editorType: string | undefined;
  @Input() icon?: string;
  @Input() showMedia = true;
  @Input() showDeleteButton = false;

  @Output() deleteStructInstance: EventEmitter<void> = new EventEmitter<void>();

  currentResourceName?: string;
  currentResource?: DataInstance;
  currentResourceFieldComponents: DynamicFieldObject[] = [];
  field?: Field;

  routeSub?: Subscription;

  constructor(
    private fieldFactory: DynamicFieldService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private loadingScreenService: LoadingScreenService,
  ) {}

  get structTypeDescription(): string {
    if (!this.resourceStructType) return '';
    return this.dataService.getStructType(this.resourceStructType).description;
  }

  async ngOnInit() {
    this.editorType = this.editorType || this.data?.fieldEditor?.editorType;

    await this.loadingScreenService.show(async () => {
      await firstValueFrom(this.fieldFactory.dynamicFieldServiceInitialized.pipe(filter(Boolean)));
      // If this component is not used as a child component,
      // we need to get the resourceStructType and currentResourceUid from the route
      if (!this.data && (!this.resourceStructType || !this.currentResourceUid)) {
        this.routeSub = this.route.params.subscribe((params) => {
          this.resourceStructType = params['resource'];
          this.currentResourceUid = params['resourceUid'];
          this.refreshData().then();
        });
      }
      this.refreshData().then();
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && !changes['data'].firstChange) {
      await this.refreshData();
    } else if (changes['currentResourceUid'] && !changes['currentResourceUid'].firstChange) {
      await this.refreshData();
    }
  }

  onDelete() {
    this.deleteStructInstance.emit();
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
  }

  async refreshData() {
    if (this.data && this.data.value) {
      const instance = await this.dataService.getDataInstance(this.data.value);
      if (!instance) return console.warn(`Instance ${this.data.value} not found`);

      this.resourceStructType = instance.dataType;
      this.currentResourceUid = instance.uid;
    }

    if (this.resourceStructType && !this.currentResourceUid) {
      // If the resource struct type is set, but the resource uid is not, we need to create a new resource, but the user has to do that
      console.warn('Resource uid not found');
      return;
    }

    if (!this.resourceStructType || !this.currentResourceUid) {
      console.warn(`Resource struct type (${this.resourceStructType}) or resource uid (${this.currentResourceUid}) not found`);
      return;
    }

    this.currentResource = await this.dataService.getDataInstanceFromDB(
      environment.defaultGame,
      environment.dataPackage,
      this.resourceStructType,
      this.currentResourceUid,
    );

    // Get the name of the current resource
    this.currentResourceName =
      this.titleOverride ??
      (this.currentResource.fieldValues.find((fv) => fv.field === 'name')?.value as string | undefined) ??
      this.currentResource.uid;

    // Get the field components for the current resource
    const fieldComponentsMap = this.fieldFactory.getDynamicFieldObjects(
      this.showMedia
        ? this.currentResource.fieldValues
        : this.resourceStructType === 'ImagePlaceableMedia'
          ? this.currentResource.fieldValues.filter((fv) => fv.field !== 'image')
          : this.resourceStructType === 'VideoPlaceableMedia'
            ? this.currentResource.fieldValues.filter((fv) => fv.field !== 'video')
            : this.resourceStructType === 'SolidColorPlaceableMedia'
              ? this.currentResource.fieldValues.filter((fv) => fv.field !== 'color')
              : this.currentResource.fieldValues,
      this.currentResource.uid,
      this.resourceStructType,
      this.showMedia,
    );

    // Since the fieldComponentsMap is a map, we need to convert it to a list.
    // (This is due to technical issues in the activity component, see the comments there).
    this.currentResourceFieldComponents = Object.values(fieldComponentsMap);

    // Sort the fields by position
    if (this.currentResourceFieldComponents.some((field) => field.data.fieldEditor)) {
      this.currentResourceFieldComponents.sort((a, b) => {
        // Provide a default position value when fieldEditor is undefined
        const positionA = a.data.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
        const positionB = b.data.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
        return positionA - positionB;
      });
    }
  }

  addStructInstance() {
    if (!this.data) throw new Error('Data not found');

    this.dataService.getDataInstance(this.data.dataInstanceUid).then((data) => {
      if (!data || !this.data) throw new Error('Data not found');

      this.field = this.dataService.getField(this.data.fieldId, data.dataType);
      if (!this.field) throw new Error('Field not found');

      const start = this.field.type.indexOf('<') + 1;
      const end = this.field.type.lastIndexOf('>');
      const result = this.field.type.slice(start, end);

      this.dataService.initStruct(result).then((dataInstance) => {
        if (!dataInstance || !this.data) throw new Error('Data not found');

        this.data.value = dataInstance.uid;

        this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value).then(() => {
          this.currentResource = dataInstance;
          this.resourceStructType = dataInstance.dataType;
          this.currentResourceName = dataInstance.uid;

          const fieldComponentsMap = this.fieldFactory.getDynamicFieldObjects(
            dataInstance.fieldValues,
            dataInstance.uid,
            dataInstance.dataType,
            true,
          );
          this.currentResourceFieldComponents = Object.values(fieldComponentsMap);
        });
      });
    });
  }
}
