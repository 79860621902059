<div *ngIf="table" class="p-5">

  <div class="menu">
    <button (click)="undo()" [disabled]="!canUndo()" class="btn btn-secondary" title="Undo last changes"><i
      class="fas fa-undo"></i></button>
    <button (click)="redo()" [disabled]="!canRedo()" class="btn btn-secondary" title="Redo last changes"><i
      class="fas fa-redo"></i></button>
    <button (click)="copyTable()" class="btn btn-secondary" title="Copy table"><i class="bi bi-copy"></i></button>
    <button (click)="deleteTable()" class="btn btn-danger" title="Delete table"><i class="bi bi-trash"></i></button>
    <button (click)="updateTable()" class="btn btn-primary" title="Save table"><i class="far fa-save"></i></button>
  </div>

  <div>
    <div class="label">TypeId</div>
    <input #typeId="ngModel"
           (ngModelChange)="saveCurrentState()"
           [(ngModel)]="table.typeId"
           class="form-control"
           id="typeId"
           name="typeId"
           required
           type="text">
    <div *ngIf="typeId.invalid && typeId.touched" class="alert alert-danger">
      Type ID is required.
    </div>
  </div>

  <div>
    <div class="label">Name</div>
    <input #name="ngModel"
           (ngModelChange)="saveCurrentState()"
           [(ngModel)]="table.name"
           class="form-control"
           id="name"
           name="name"
           required
           type="text">
    <div *ngIf="name.invalid && name.touched" class="alert alert-danger">
      Name is required.
    </div>
  </div>

  <div>
    <div class="label">Description</div>
    <textarea (ngModelChange)="saveCurrentState()" [(ngModel)]="table.description" class="form-control"
              type="text"></textarea>
  </div>

  <div *ngIf="isStructType(table) && type === 'StructType'">
    <div>
      <div class="label">Is Resource</div>
      <input
        (ngModelChange)="saveCurrentState()"
        [(ngModel)]="table.isResource"
        [class]="'form-check-input ng-untouched ng-pristine ng-valid'"
        type="checkbox"
      >
    </div>
    <div class="label">Fields</div>
    <div (cdkDropListDropped)="drop($event)" cdkDropList class="accordion pb-3" id="fields">
      <div *ngFor="let field of table.fields; let i = index" [cdkDragDisabled]="openAccordionIndex === i" cdkDrag
           class="accordion-item">


        <ng-template #fieldMigration let-modal>
          <div class="p-3">
            <div class="modal-header">Migrate Field</div>
            <div class="modal-body">
              <div class="pb-1">New Field ID</div>
              <input #typeIdInput class="form-control" type="text"/>
            </div>
            <div class="modal-footer">
              <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary">Cancel</button>
              <button (click)="migrateField(field.fieldId, typeIdInput.value);modal.dismiss('Migrate Click')"
                      *ngIf="typeIdInput.value"
                      class="btn btn-primary"
              >
                Migrate
              </button>
            </div>
          </div>
        </ng-template>

        <ng-template #fieldMigrationToSubType let-modal>
          <div *ngIf="isStructType(table)" class="p-3">
            <div class="modal-header">Migrate Field to subtype</div>
            <div class="modal-body">

              <div class="pb-1">Parent Field to migrate to</div>
              <select [(ngModel)]="selectedParentField" class="form-select">
                <option *ngFor="let f of getFieldsWithTypeStruct()" [ngValue]="f">{{ f.fieldId }}</option>
              </select>

              <div *ngIf="selectedParentField">
                <div class="pb-1">Subtype Field to migrate to</div>
                <select [(ngModel)]="selectedSubField" class="form-select">
                  <option *ngFor="let f of getFieldsFromSubStruct(selectedParentField.fieldId)"
                          [ngValue]="f">{{ f.fieldId }}
                  </option>
                </select>
              </div>

            </div>
            <div class="modal-footer">
              <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary">Cancel</button>
              <button
                (click)="migrateFieldToSubStruct(selectedParentField ,selectedSubField, field);modal.dismiss('Migrate Click')"
                *ngIf="selectedParentField && selectedSubField"
                class="btn btn-primary">
                Migrate
              </button>
            </div>
          </div>
        </ng-template>

        <ng-template #fieldMigrationFromSubType let-modal>
          <div *ngIf="isStructType(table)" class="p-3">
            <div class="modal-header">Migrate Field from subtype</div>
            <div class="modal-body">

              <div class="pb-1">Sub Field to migrate</div>
              <select [(ngModel)]="selectedSubField" class="form-select">
                <option *ngFor="let f of getFieldsFromSubStruct(field.fieldId)"
                        [ngValue]="f">{{ f.fieldId }}
                </option>
              </select>

              <div class="pb-1">Field to migrate to</div>
              <select [(ngModel)]="selectedParentField" class="form-select">
                <option *ngFor="let f of table.fields" [ngValue]="f">{{ f.fieldId }}</option>
              </select>
            </div>


            <div class="modal-footer">
              <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary">Cancel</button>
              <button
                (click)="migrateFieldToSubStruct(selectedParentField ,selectedSubField, field);modal.dismiss('Migrate Click')"
                *ngIf="selectedParentField && selectedSubField"
                class="btn btn-primary">
                Migrate
              </button>
            </div>
          </div>
        </ng-template>


        <h2 [id]="'heading' + i" class="accordion-header">
          <button (click)="toggleAccordion(i)" [attr.aria-controls]="'collapse' + i"
                  [attr.data-bs-target]="'#collapse' + i"
                  [class.collapsed]="openAccordionIndex !== i" aria-expanded="false" class="accordion-button"
                  data-bs-toggle="collapse" type="button">
            {{ field.name }}
          </button>
        </h2>
        <div [attr.aria-labelledby]="'heading' + i" [class.show]="openAccordionIndex === i" [id]="'collapse' + i"
             class="accordion-collapse collapse" data-bs-parent="#fields">
          <div class="accordion-body d-flex justify-content-between gap-3">
            <div class="w-100">
              <div>
                <div class="label">Field ID</div>
                <div class="d-flex align-items-center gap-2">
                  <input #fieldId="ngModel"
                         (ngModelChange)="saveCurrentState()"
                         [(ngModel)]="field.fieldId"
                         class="form-control"
                         id="fieldId"
                         name="fieldId"
                         required
                         type="text">
                  <div class="dropdown h-100">
                    <div aria-expanded="false" aria-haspopup="true" class="bi bi-three-dots-vertical"
                         data-bs-toggle="dropdown" id="dropdownMenuButtonStructId" type="button"></div>
                    <div aria-labelledby="dropdownMenuButtonStructId" class="dropdown-menu">
                      <button (click)="openModal(fieldMigration)" class="dropdown-item">Migrate Field</button>
                      <button (click)="openModal(fieldMigrationToSubType)" class="dropdown-item">Migrate Field to
                        Sub-Type
                      </button>
                    </div>
                  </div>
                </div>
                <div *ngIf="fieldId.invalid && fieldId.touched" class="alert alert-danger">
                  Field ID is required.
                </div>
              </div>
              <div>
                <div class="label">Name</div>
                <input #fieldName="ngModel"
                       (ngModelChange)="saveCurrentState()"
                       [(ngModel)]="field.name"
                       class="form-control"
                       id="fieldName"
                       name="fieldName"
                       required
                       type="text">
                <div *ngIf="fieldName.invalid && fieldName.touched" class="alert alert-danger">
                  Name is required.
                </div>
              </div>
              <div>
                <div class="label">Description</div>
                <textarea (ngModelChange)="saveCurrentState()" [(ngModel)]="field.description" class="form-control"
                          type="text"></textarea>
              </div>
              <div>
                <div class="label">Type</div>
                <div class="d-flex align-items-center gap-2">
                  <input (input)="filterTypes(field.type)"
                         (ngModelChange)="saveCurrentState()"
                         [(ngModel)]="field.type"
                         [matAutocomplete]="auto"
                         aria-label="Number"
                         class="form-control"
                         matInput
                         placeholder="Pick a type"
                         type="text">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let type of filteredSchemaTypes" [value]="type">
                      {{ type }}
                    </mat-option>
                  </mat-autocomplete>
                  <div *ngIf="field.type.includes('<') && isExistingStruct(field.type)" class="dropdown h-100">
                    <div aria-expanded="false" aria-haspopup="true" class="bi bi-three-dots-vertical"
                         data-bs-toggle="dropdown" id="dropdownMenuButtonStructType" type="button"></div>
                    <div aria-labelledby="dropdownMenuButtonStructType" class="dropdown-menu">
                      <button (click)="visitTable(field.type)"
                              class="dropdown-item">
                        Visit Table
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="field.type !== 'ImageRef' &&
                          field.type !== 'AudioRef' &&
                          field.type !== 'VideoRef' &&
                          field.type !== 'FileRef' &&
                          field.type !== 'Icon' &&
                          !field.type.startsWith('Struct') &&
                          !field.type.startsWith('EnumRef') &&
                          field.required">
                <div class="label">Default Value</div>

                <select (ngModelChange)="saveCurrentState()" *ngIf="field.type.startsWith('Enum')"
                        [(ngModel)]="field.defaultValue"
                        class="form-select">
                  <option *ngFor="let option of getEnumOptions(field.type)">{{ option }}</option>
                </select>

                <select (ngModelChange)="saveCurrentState()" *ngIf="field.type.startsWith('Select')"
                        [(ngModel)]="field.defaultValue"
                        class="form-select">
                  <option *ngFor="let option of getSelectOptions(field.type)">{{ option }}</option>
                </select>
                <input
                  (ngModelChange)="saveCurrentState()"
                  *ngIf="field.type === 'bool'"
                  [(ngModel)]="field.defaultValue"
                  [class]="'form-check-input ng-untouched ng-pristine ng-valid'"
                  type="checkbox"
                >
                <input (ngModelChange)="saveCurrentState()"
                       *ngIf="!field.type.startsWith('Enum') && !field.type.startsWith('Select') && field.type !== 'bool'"
                       [(ngModel)]="field.defaultValue"
                       [class]="'form-control'"
                       [type]="field.type === 'int' || field.type === 'float' ? 'number' : 'text'"
                       lang="en">
              </div>
              <div>
                <div class="label">Required</div>
                <input (ngModelChange)="saveCurrentState()" [(ngModel)]="field.required"
                       class="form-check-input ng-untouched ng-pristine ng-valid"
                       type="checkbox">
              </div>
            </div>

            <div class="w-100">
              <div *ngIf="field.fieldEditor">
                <div class="label">Field Editor</div>
                <div class="border border-secondary p-3">
                  <div>
                    <div class="label">Editor Type</div>
                    <select (ngModelChange)="saveCurrentState()" [(ngModel)]="field.fieldEditor.editorType"
                            class="form-select">
                      <option *ngFor="let option of fieldEditorTypes"
                              [value]="option.value">{{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <div class="label">Show if</div>
                    <select (change)="saveCurrentState()"
                            class="form-select">
                      <option *ngFor="let f of table.fields" [value]="f.name">{{ f.name }}</option>
                    </select>
                    <div class="label">has a value of</div>
                    <input class="form-control">
                  </div>
                  <div>
                    <div class="label">Position</div>
                    <input (ngModelChange)="saveCurrentState()" [(ngModel)]="field.fieldEditor.position"
                           class="form-control"
                           type="number">
                  </div>
                  <div *ngIf="table.isResource">
                    <div class="label">Show in resource column</div>
                    <input (ngModelChange)="saveCurrentState()" [(ngModel)]="field.fieldEditor.showResource"
                           class="form-check-input ng-untouched ng-pristine ng-valid"
                           role="switch"
                           type="checkbox">
                  </div>
                  <div
                    *ngIf="field.type.startsWith('EnumRef') || field.type.startsWith('StructRef') || field.type.startsWith('List<StructRef') || field.type.startsWith('List<EnumRef')">
                    <div class="label">Scope of the reference</div>
                    <select (ngModelChange)="saveCurrentState()" [(ngModel)]="field.fieldEditor.scope"
                            class="form-select">
                      <option *ngFor="let scope of ['local', 'global']" [value]="scope">{{ scope }}</option>
                    </select>
                  </div>
                  <div
                    *ngIf="field.type.startsWith('EnumRef') || field.type.startsWith('StructRef') || field.type.startsWith('List<StructRef') || field.type.startsWith('List<EnumRef')">
                    <div class="label">Field to display in dropdown</div>
                    <input (ngModelChange)="saveCurrentState()" [(ngModel)]="field.fieldEditor.displayField"
                           class="form-control"
                           type="text">
                  </div>
                </div>
                <div class="p-3">
                  <button (click)="removeItem(field.fieldId)" class="btn btn-danger float-end"
                          title="Remove {{field.name}}"><i class="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <button (click)="addItem()" class="btn btn-primary bi bi-plus-circle" title="Add field"></button>
    </div>
  </div>

  <div *ngIf="isEnumType(table) && type === 'EnumType'">
    <div class="label">Options</div>
    <div class="accordion pb-3" id="enum-options">
      <div *ngFor="let option of table.options; let i = index; trackBy: trackByFn" class="accordion-item">
        <h2 [id]="'heading' + i" class="accordion-header">
          <button [attr.aria-controls]="'collapse' + i" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false"
                  class="accordion-button collapsed" data-bs-toggle="collapse" type="button">
            {{ option }}
          </button>
        </h2>
        <div [attr.aria-labelledby]="'heading' + i" [id]="'collapse' + i" class="accordion-collapse collapse"
             data-bs-parent="#enum-options">
          <div class="accordion-body">
            <div class="pb-3">
              <div>
                <div class="label">StructType</div>
                <div class="d-flex align-items-center gap-2">
                  <input (input)="filterStructs(table.options[i])"
                         (ngModelChange)="saveCurrentState()"
                         [(ngModel)]="table.options[i]"
                         [matAutocomplete]="auto"
                         aria-label="Number"
                         class="form-control w-50"
                         matInput
                         placeholder="Pick a StructType"
                         type="text">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let type of filteredStructsTypes" [value]="type">
                      {{ type }}
                    </mat-option>
                  </mat-autocomplete>
                  <div class="dropdown h-100">
                    <div aria-expanded="false" aria-haspopup="true" class="bi bi-three-dots-vertical"
                         data-bs-toggle="dropdown" id="dropdownMenuButtonEnum" type="button"></div>
                    <div aria-labelledby="dropdownMenuButtonEnum" class="dropdown-menu">
                      <button (click)="visitTable(option)" [disabled]="!isExistingStruct(option)"
                              class="dropdown-item">Visit Table
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-3">
                <button (click)="removeItem(option)" class="btn btn-danger float-end" title="Remove {{option}}"><i
                  class="bi bi-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <button (click)="addItem()" class="btn btn-primary bi bi-plus-circle" title="Add option"></button>
    </div>
  </div>

  <div *ngIf="isSelectType(table) && type === 'SelectType'">
    <div class="label">Options</div>
    <div class="accordion pb-3" id="select-options">
      <div *ngFor="let option of table.options; let i = index" class="accordion-item">
        <h2 [id]="'heading' + i" class="accordion-header">
          <button [attr.aria-controls]="'collapse' + i" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false"
                  class="accordion-button collapsed" data-bs-toggle="collapse" type="button">
            {{ option.label }}
          </button>
        </h2>
        <div [attr.aria-labelledby]="'heading' + i" [id]="'collapse' + i" class="accordion-collapse collapse"
             data-bs-parent="#select-options">
          <div class="accordion-body">
            <div class="pb-3">
              <div>
                <div class="label">OptionId</div>
                <input #optionId="ngModel"
                       (ngModelChange)="saveCurrentState()"
                       [(ngModel)]="option.optionId"
                       class="form-control"
                       id="optionId"
                       name="optionId"
                       required
                       type="text">
                <div *ngIf="optionId.invalid && optionId.touched" class="alert alert-danger">
                  ID is required.
                </div>
              </div>
              <div>
                <div class="label">Label</div>
                <input #label="ngModel"
                       (ngModelChange)="saveCurrentState()"
                       [(ngModel)]="option.label"
                       class="form-control"
                       id="label"
                       name="label"
                       required
                       type="text">
                <div *ngIf="label.invalid && label.touched" class="alert alert-danger">
                  Label is required.
                </div>
              </div>
              <div class="p-3">
                <button (click)="removeItem(option.optionId)" class="btn btn-danger float-end"
                        title="Remove {{option.label}}"><i class="bi bi-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <button (click)="addItem()" class="btn btn-primary bi bi-plus-circle" title="Add option"></button>
    </div>
  </div>
</div>
