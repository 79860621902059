import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { ConfirmationModalComponent } from '../../_components/UI-elements/confirmation-modal/confirmation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService {
  private confirmationSubject = new Subject<boolean>();

  constructor(private modalService: NgbModal) {}

  confirm(message: string): Observable<boolean> {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
    });

    modalRef.componentInstance.message = message;

    return modalRef.componentInstance.result;
  }

  getConfirmation(): Observable<boolean> {
    return this.confirmationSubject.asObservable();
  }

  setConfirmation(value: boolean) {
    this.confirmationSubject.next(value);
  }
}
