<ng-container
  *ngTemplateOutlet="editorType === 'SeamlessInline' ? seamlessInlineTemplate : editorType === 'Hidden' ? hiddenTemplate : template"
>
</ng-container>

<ng-template #seamlessInlineTemplate>
  <ng-container [appField]="fieldComponent"></ng-container>
  <ng-content></ng-content>
</ng-template>

<ng-template #hiddenTemplate>
  <div></div>
</ng-template>

<ng-template #template>
  <div class="mode-container">
    <div class="input-group">
      <div [ngbPopover]="description" class="mode-label-above px-2 pt-1" triggers="hover">
        {{ name }}:
      </div>
      <div [ngbPopover]="description" class="mode-normal input-group-prepend" triggers="hover">
        <span class="input-group-text">{{ name }}</span>
      </div>
      <div class="form-control">
        <ng-container [appField]="fieldComponent"></ng-container>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>
